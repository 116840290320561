import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import ItemConnector from './item-connector';

const ActivityDivisor = styled.div`
  max-width: 100%;
  align-items: center;
  align-items: flex-start;
  display: flex !important;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-end;
`;

const ActivityContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 0rem 0.3rem;
  width: 100%;
  position: relative;
`;

const CardContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
`;

type ActivitySlideProps = {
  children: React.ReactNode;
  isFirst?: boolean;
  isLast?: boolean;
  isDeactivated?: boolean;
};

const DigitalPracticeItem = ({
  children,
  isFirst,
  isLast,
  isDeactivated,
}: ActivitySlideProps): EmotionJSX.Element => (
  <ActivityDivisor>
    <ActivityContainer>
      <CardContainer>{children}</CardContainer>
    </ActivityContainer>
    <ItemConnector
      isHiddenLeft={isFirst}
      isHiddenRight={isLast}
      isDeactivated={isDeactivated}
    />
  </ActivityDivisor>
);

export default DigitalPracticeItem;
