import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { PrintableBody2 } from '../../../printable-styles/printable-typography';
import type { MediaType } from '../../../types/Media';
import type { TableContentType } from '../../../types/Table';
import { Accordion } from '../../Accordion';
import { Media } from '../../Media';
import { Table } from '../../Table';

type Props = {
  children: ReactNode;
  media?: MediaType[] | null;
  isPrintable?: boolean;
  tables?: TableContentType[];
  summary: string;
};

const Text = styled(PrintableBody2)`
  flex: 1;
  color: ${({ theme }) => theme.tokens.color.alias.cm.text.text.value};
`;

function BaseTip({
  children,
  isPrintable,
  media,
  summary,
  tables,
}: Props): JSX.Element {
  const hasTable = !!(tables?.length && tables.length > 0);

  return (
    <Accordion summary={summary} isPrintable={isPrintable}>
      {children}
      {media && <Media media={media} />}
      {hasTable && <Table tables={tables} />}
    </Accordion>
  );
}

export { BaseTip, Text };
