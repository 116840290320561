import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { Checkbox, Icon, Typography } from '@innovamat/glimmer-components';

const Container = styled.div`
  align-items: center;
  aspect-ratio: 12 / 7;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 0.25rem;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  position: relative;
  width: 100%;
`;

const TitleContainer = styled.div`
  text-align: center;
  width: 8rem;
`;

const CheckBoxWrapper = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 2.5rem;
`;

const IconWrapper = styled.div`
  bottom: -0.5rem;
  left: 0.9rem;
  position: absolute;
`;

type CardProps = {
  percentage?: number;
  showTouchIcon?: boolean;
  title?: string;
};

const InformationCard = ({
  percentage,
  showTouchIcon,
  title,
}: CardProps): EmotionJSX.Element => {
  return (
    <Container>
      {percentage && <Typography.H3>{percentage}%</Typography.H3>}
      {title && (
        <TitleContainer>
          <Typography.Body3>{title}</Typography.Body3>
        </TitleContainer>
      )}
      {showTouchIcon && (
        <>
          <CheckBoxWrapper>
            <Checkbox
              checked={false}
              customStyles="background: rgba(255, 255, 255, 0.6);"
            />
          </CheckBoxWrapper>
          <IconWrapper>
            <Icon icon="TouchIcon" size="XL" iconColor="white" />
          </IconWrapper>
        </>
      )}
    </Container>
  );
};

export default InformationCard;
