import styled from '@emotion/styled';
import {
  Grounder,
  HeadingSection,
  Tabs,
  theme,
  ToggleButton,
  Typography,
} from '@innovamat/glimmer-components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Stages } from '@innovamat/glow-api-client';
import { ERROR_TYPE, ErrorPage } from '../../../navigation';
import ReportsLoader from '../../components/reports-loader';
import { AdminDashboardTable } from '../admin-dashboard-table';
import { NoClassesError } from './components/no-classes-error';

type Props = {
  organizationId?: string;
  stagesAreLoading: boolean;
  stages: { [key: string]: boolean };
  isError?: boolean;
  searchParams: URLSearchParams;
  onSetSearchParams: (search: string) => void;
  setEventData?: (eventType: string, eventProperties?: any) => void;
  goToClassroom?: (classroomId: string) => void;
  goToTestsAndResults?: (classroomId: string) => void;
};

export type DashboardProps = {
  organizationId?: string;
  tabsAreLoaded?: boolean;
  goToClassroom?: (classroomId: string) => void;
  goToTestsAndResults?: (classroomId: string) => void;
  noDataFilter?: boolean;
  stage: Stages;
  setAllRowsFilledOrEmpty: (allFilledOrEmpty: boolean | undefined) => void;
};

type DashboardRendererProps = {
  stageActive: StageType;
};

const STAGE_TYPE = {
  EARLY_YEARS: 'EarlyYears',
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
} as const;

type StageType = (typeof STAGE_TYPE)[keyof typeof STAGE_TYPE];

const stageToEvent = {
  EarlyYears: 'ey',
  Primary: 'pe',
  Secondary: 'se',
};

const Wrapper = styled.div`
  margin-right: -24px;
`;

const Container = styled.div`
  @media (min-width: ${theme.breakpoints.xl}) {
    max-width: 1280px;
    margin: 0 auto;
  }
`;

const InnerContainer = styled.div``;

const Title = styled(HeadingSection)`
  position: sticky !important;
  left: 0 !important;
`;

const TableContainer = styled.div`
  display: grid;
  padding-right: 24px;
`;

const SubtitleHeader = styled(Typography.H4)`
  padding: 16px 0px 14px;
  position: sticky;
  left: 0;

  border-top: 1px solid
    ${({ theme }) => theme.tokens.color.alias.cm.border['border-subtle'].value};
`;

const TabsAndToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ToggleContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

function AdminDashboard({
  organizationId,
  stagesAreLoading,
  stages,
  isError,
  onSetSearchParams,
  searchParams,
  setEventData,
  goToClassroom,
  goToTestsAndResults,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const stageFromURL = searchParams.get('stage');

  const getInitialStage = useMemo(
    () =>
      (stages: { [key: string]: boolean }): StageType | undefined => {
        if (stageFromURL) return stageFromURL as StageType;

        if (stages.primaria) return STAGE_TYPE.PRIMARY;
        if (stages.infantil) return STAGE_TYPE.EARLY_YEARS;
        if (stages.secundaria) return STAGE_TYPE.SECONDARY;

        return undefined;
      },
    [stageFromURL]
  );

  const [stageActive, setStageActive] = useState<StageType>(
    getInitialStage(stages) || STAGE_TYPE.PRIMARY
  );

  useEffect(() => {
    const initStage = getInitialStage(stages);

    if (!stageFromURL) {
      if (initStage) {
        searchParams.set('stage', stageActive);
        onSetSearchParams(searchParams.toString());
      }
    }
  }, [
    getInitialStage,
    onSetSearchParams,
    searchParams,
    stageActive,
    stageFromURL,
    stages,
  ]);

  useEffect(() => {
    if (setEventData) {
      setEventData('admin_dashboard_access', {
        dashboard_tab: stageToEvent[stageActive],
      });
    }
  }, [setEventData, stageActive, organizationId]);

  useEffect(() => {
    const appContent = document.getElementById('app-content');
    if (appContent) {
      appContent.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    }
    return () => {
      if (appContent) {
        appContent.style.overflow = '';
        document.body.style.overflow = 'scroll';
      }
    };
  }, []);

  const isStageActive = (section: StageType): boolean =>
    stageActive === section;

  const stagesMapping: {
    key: StageType;
    translation: string;
    stage: boolean;
  }[] = [
    {
      key: STAGE_TYPE.EARLY_YEARS,
      translation: 'common.stage-infantil',
      stage: stages.infantil,
    },
    {
      key: STAGE_TYPE.PRIMARY,
      translation: 'common.stage-primaria',
      stage: stages.primaria,
    },
    {
      key: STAGE_TYPE.SECONDARY,
      translation: 'common.stage-secundaria',
      stage: stages.secundaria,
    },
  ];

  const hasClasses = stages.infantil || stages.primaria || stages.secundaria;

  const handleChangeStage = (stage: StageType): void => {
    setStageActive(stage);
    searchParams.set('stage', stage);
    onSetSearchParams(searchParams.toString());
  };

  const [noDataFilter, setNoDataFilter] = useState<boolean>(false);
  const [allRowsFilledOrEmpty, setAllRowsFilledOrEmpty] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    if (allRowsFilledOrEmpty) setNoDataFilter(false);
  }, [allRowsFilledOrEmpty]);

  const DashboardRenderer: React.FC<DashboardRendererProps> = ({
    stageActive,
  }) => {
    if (stagesAreLoading) {
      return <ReportsLoader />;
    }

    if (isError) {
      return <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />;
    }

    if (!stagesAreLoading && !hasClasses) {
      return <NoClassesError />;
    }

    const stageActiveToQueryStage = {
      EarlyYears: 'EY',
      Primary: 'PE',
      Secondary: 'SE',
    };

    const stage = stageActiveToQueryStage[stageActive] as Stages;

    return (
      <AdminDashboardTable
        organizationId={organizationId}
        tabsAreLoaded={!stagesAreLoading}
        goToClassroom={goToClassroom}
        goToTestsAndResults={goToTestsAndResults}
        noDataFilter={noDataFilter}
        stage={stage}
        setAllRowsFilledOrEmpty={setAllRowsFilledOrEmpty}
      />
    );
  };

  return (
    <Wrapper>
      <Container>
        <InnerContainer>
          <Title title={t('sidebar.items.adminDashboard')} />
          <TabsAndToggleContainer>
            <Tabs
              tabs={stagesMapping
                .filter((stage) => stage.stage)
                .map((stage) => ({
                  selected: isStageActive(stage.key),
                  text: t(stage.translation),
                  onClick: () => handleChangeStage(stage.key),
                  dataTestId: `ADRE_${stage.key.toUpperCase()}TAB`,
                }))}
            />
            {hasClasses && allRowsFilledOrEmpty === false && (
              <ToggleContainer>
                <ToggleButton
                  isActive={!noDataFilter}
                  onToggle={() => setNoDataFilter(!noDataFilter)}
                />
                <Typography.Body2>{t('reports.admin.toggle')}</Typography.Body2>
              </ToggleContainer>
            )}
          </TabsAndToggleContainer>
          {hasClasses && (
            <SubtitleHeader>{t('reports.admin.subtitle')}</SubtitleHeader>
          )}
        </InnerContainer>

        <TableContainer>
          <DashboardRenderer stageActive={stageActive} />
        </TableContainer>
      </Container>
      <Grounder />
    </Wrapper>
  );
}

export { AdminDashboard };
