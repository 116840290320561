import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  @page {
    size: A4;
    margin: 12.5mm; 
  }

  @media print {
    .standard-section {
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
      box-decoration-break: clone;
      -webkit-box-decoration-break: clone;
    }

    .header-bar {
      margin-bottom: 16px;
    }
  }
`;

export const Wrapper = styled.div`
  color: #000000;
  padding: 48px;
  max-width: 800px;
  height: var(--realVh);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-bottom: 58px;

  @page {
    margin: 12.5mm;
  }
`;

export const Bold = styled.span`
  font-weight: 500;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 68px;
  table {
    margin-top: 12px;
  }

  @media print {
    page-break-after: always;

    thead {
      display: table-header-group;
      margin-bottom: 16px;
    }
    tbody {
      margin-top: 16px;
      margin-top: 16px;
    }
    tfoot {
      display: table-footer-group;
    }
  }
`;

export const THeadCell = styled.th`
  vertical-align: top;
  padding: 0;
  border: none;
`;

export const TBodyCell = styled.td`
  vertical-align: top;
  padding: 0;
  border: none;
`;

export const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  padding-bottom: 4px;
  border-bottom: 1.5px solid #f5f4f4;
  margin-bottom: 16px;
`;

export const FileTitle = styled.h1`
  font-size: 27px;
  font-weight: 700;
  color: #000000;
  margin: 0;
  line-height: 1;
  display: flex;
  align-items: baseline;
`;

export const TabletTitle = styled.div`
  min-width: 145px;
  height: 40px;
  flex-shrink: 0;
  background-color: #f5aa93;
  padding: 7px 16px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

export const Stretch = styled.h2`
  color: #000000;
  font-size: 21px;
  font-weight: 700;
  line-height: normal;
`;

export const ModuleName = styled.h2`
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FileDescription = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  p,
  li {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 40px;
  }

  li {
    list-style-type: disc;
    display: list-item;
  }
`;

export const KeepTogether = styled.div`
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
    display: inline-block;
  }

  .activity-card {
    width: 322px;
  }
`;

export const UnlockedAppletsTitle = styled.h1`
  margin-top: 24px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
`;

export const StandardsContainer = styled.div`
  margin-top: 16px;
  gap: 16px;
`;

export const StandardSection = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  padding: 20px;
  background-color: #f1f3f2;
`;

export const StandardInfo = styled.h2`
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 16px;
  @media print {
    page-break-after: avoid;
    break-after: avoid;
  }
`;

export const ActivityGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 20px;
`;

export const ActivityGridRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 20px;
  margin-top: 12px;

  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const ActivityCard = styled.div<{ isFirstLineOfGroup?: boolean }>`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  gap: 8px;
  @media print {
    margin-top: ${({ isFirstLineOfGroup }) =>
      isFirstLineOfGroup ? '-5px' : '0'};
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;

export const ActivityImage = styled.img`
  width: 128px;
  height: 72px;
  border-radius: 4px;
  object-fit: cover;
  flex-shrink: 0;
`;

export const ActivityInfo = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  line-height: 14px;
  color: #1b1b1d;
`;
