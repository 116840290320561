import { keyframes } from '@emotion/react';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import { useEventLogging } from '@innovamat/event-logging';
import { Slider, ToggleButton, Tooltip } from '@innovamat/glimmer-components';
import { useAppletsBlackListMutation } from '@innovamat/glow-api-client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-domv6';
import { useResponsiveSlide } from '../../../contents/components';
import { useNavigation } from '../../../navigation';
import type { AppletsOfTheWeekActivity } from '../../types';
import { AppletCard } from '../cards/applet-card';
import InformationCard from '../cards/information-card';
import DigitalPracticeItem from './digital-practice-item';

type Props = {
  activities: AppletsOfTheWeekActivity[];
  backgroundImg: string;
  canEnableDisableActivities: boolean;
};

const Wrapper = styled.div<{ backgroundImage: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem 1.5rem;
  border-radius: 0.5rem;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url(${({ backgroundImage }) => backgroundImage}) lightgray 50% / cover
      no-repeat;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const slideIn = keyframes`
  from {
    transform: translateX(-100%) rotate(-5deg);
    opacity: 0;
  }
  to {
    transform: translateX(0) rotate(0);
    opacity: 1;
  }
`;

const AnimatedItem = styled.div<{ delay: number }>`
  animation: ${slideIn} 0.3s cubic-bezier(0.2, 0.8, 0.2, 1);
  animation-delay: ${({ delay }) => delay}s;
  animation-fill-mode: both;
`;

const DisableButton = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  span {
    display: block;
  }
`;

const DigitalPracticeSlider = ({
  activities,
  backgroundImg,
  canEnableDisableActivities,
}: Props): EmotionJSX.Element => {
  const { setEventData } = useEventLogging();
  const { t } = useTranslation();
  const { goToResource } = useNavigation();
  const { classroomId } = useParams();

  const { slidesToShow, initialSlide } = useResponsiveSlide({
    resources: activities,
    relatedResources: false,
  });

  const { mutate: updateActivityEnabled } = useAppletsBlackListMutation();

  const [enabledActivities, setEnabledActivities] = useState<
    Record<string, boolean>
  >(() =>
    activities.reduce((acc, activity) => {
      acc[String(activity.uuid)] = activity.enabled;
      return acc;
    }, {} as Record<string, boolean>)
  );

  useEffect(() => {
    setEnabledActivities(
      activities.reduce((acc, activity) => {
        acc[String(activity.uuid)] = activity.enabled;
        return acc;
      }, {} as Record<string, boolean>)
    );
  }, [activities]);

  const handleRequestList = (): void => {
    setEventData('content_list_arrow_click', {});
  };

  const handleOnClickApplet = (id: string): void => {
    goToResource({
      type: 'applet',
      resourceId: id,
      openInNewTab: true,
    });
  };

  const toggleActivity = (id: string, value: boolean): void => {
    const activity = activities.find((a) => String(a.uuid) === id);
    const unlockedByLesson = activity ? activity.unlockedByLesson : '';
    updateActivityEnabled(
      {
        body: {
          activityUuid: id,
          classroomId: classroomId || '',
          enabled: !value,
          unlockedByLesson,
        },
      },
      {
        onError: () => {
          // Revert changes if mutation fails
          setEnabledActivities((prev) => ({
            ...prev,
            [id]: !prev[id],
          }));
        },
      }
    );

    setEnabledActivities((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Wrapper backgroundImage={backgroundImg} id="wrapper">
      <Container>
        <Slider
          onArrowClick={handleRequestList}
          gap="0rem"
          initialIndex={initialSlide}
          slidesToShow={slidesToShow}
          lowerOpacityOnHiddenItem={false}
        >
          {activities.map((activity, index) => {
            const isEnabled = enabledActivities[String(activity.uuid)];

            return (
              <AnimatedItem key={activity.uuid || index} delay={index * 0.1}>
                <DigitalPracticeItem
                  key={activity.uuid || index}
                  isFirst={index === 0}
                  isDeactivated={!isEnabled}
                >
                  <AppletCard
                    title={activity.data.name || ''}
                    thumbnail={activity.data.thumbnail || ''}
                    key={activity.uuid || index}
                    onClick={() => handleOnClickApplet(activity.uuid || '')}
                    enabled={isEnabled}
                  />
                  <DisableButton>
                    {!canEnableDisableActivities ? (
                      <Tooltip
                        content={t('homeWeeklyPractice.noModification.tooltip')}
                      >
                        <ToggleButton
                          aria-label="Activate activity"
                          isActive={isEnabled}
                          disabled={true}
                        />
                      </Tooltip>
                    ) : (
                      <ToggleButton
                        onToggle={() =>
                          toggleActivity(String(activity.uuid), isEnabled)
                        }
                        aria-label="Activate activity"
                        isActive={isEnabled}
                      />
                    )}
                  </DisableButton>
                </DigitalPracticeItem>
              </AnimatedItem>
            );
          })}
          <DigitalPracticeItem isLast>
            <InformationCard
              percentage={30}
              title={t('homeWeeklyPractice.30personalized')}
            />
          </DigitalPracticeItem>
        </Slider>
      </Container>
    </Wrapper>
  );
};

export default DigitalPracticeSlider;
