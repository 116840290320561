import styled from '@emotion/styled';
import type { MediaType, MediaWidth } from '../../types/Media';
import { PreprPlayer } from '../PreprPlayer';
import {
  Icon,
  ImageComponent,
  ModalOverlay,
  StateLayer,
} from '@innovamat/glimmer-components';
import { useEffect, useRef, useState } from 'react';
import { MediaModal } from './media-modal';
import { useSmartGuides } from '../../context';

type ExtendedMediaWidth =
  | MediaWidth
  | 35
  | 40
  | 45
  | 55
  | 60
  | 65
  | 70
  | 75
  | 85
  | 90
  | 95;

const Container = styled.div`
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const ImageContainer = styled.div<{
  imgWidth: MediaWidth;
  imgHeight?: number;
}>`
  position: relative;
  width: ${({ imgWidth }) => imgWidth}%;
  height: ${({ imgHeight }) => imgHeight}px;
  cursor: pointer;
  transition: opacity 0.1s ease;

  &:hover .image-stateLayer {
    border-radius: 4px;
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['smart-guides']['images-hover'].value};
  }

  svg {
    transition: opacity 0.1s ease;
    opacity: 0;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }

  @media print {
    height: auto;
  }
`;

const PrintableImageWrapper = styled.div<{
  imgWidth: ExtendedMediaWidth;
  imgHeight?: number;
}>`
  position: relative;
  width: ${({ imgWidth }) => imgWidth}%;
  height: ${({ imgHeight }) => imgHeight}px;
  cursor: pointer;
  transition: opacity 0.1s ease;

  &:hover .image-stateLayer {
    border-radius: 4px;
    background-color: ${({ theme }) =>
      theme.tokens.color.specific['smart-guides']['images-hover'].value};
  }

  svg {
    transition: opacity 0.1s ease;
    opacity: 0;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }

  @media print {
    height: auto;
  }
`;

const ZoomIcon = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 11;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Image = styled(ImageComponent)`
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
`;

const ImageControlButton = styled.button`
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ImageControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  flex-direction: row;
  z-index: 12;

  .printable-image-container:hover & {
    display: flex;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrintableImageContainer = styled.div`
  position: relative;
  width: 100%;
  &:hover {
    outline: 3px dashed #78d427;
  }
`;

const PrintableImage = styled(ImageComponent)`
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  box-sizing: border-box;
`;

const PreprPlayerWrapper = styled.div<{ width: MediaWidth }>`
  width: ${({ width }) => width}%;
`;

type Props = {
  media?: MediaType[] | null;
};

export function Media({ media }: Props): JSX.Element | null {
  const [modalStates, setModalStates] = useState<boolean[]>([]);
  const [containerWidths, setContainerWidths] = useState<
    Record<number, ExtendedMediaWidth>
  >({});
  const imageRefs = useRef<Record<number, HTMLImageElement | null>>({});
  const { hasBreakPage } = useSmartGuides();

  useEffect(() => {
    setModalStates(Array(media?.length).fill(false));
    // Inicializar los anchos de los contenedores con sus valores originales
    if (media) {
      const initialWidths = media.reduce((acc, item, index) => {
        acc[index] = item.width as ExtendedMediaWidth;
        return acc;
      }, {} as Record<number, ExtendedMediaWidth>);
      setContainerWidths(initialWidths);
    }
  }, [media]);

  const openModal = (e: any, index: number) => {
    e.stopPropagation();
    setModalStates((prevModalStates) => {
      const newModalStates = [...prevModalStates];
      newModalStates[index] = true;
      return newModalStates;
    });
  };

  const closeModal = (e: any) => {
    e.stopPropagation();
    const newModalStates = modalStates.map(() => false);
    setModalStates(newModalStates);
  };

  const handleZoomIn = (index: number) => {
    setContainerWidths((prev) => {
      const currentWidth = prev[index] || 50;
      const newWidth = Math.min(currentWidth + 5, 100) as ExtendedMediaWidth;
      return {
        ...prev,
        [index]: newWidth,
      };
    });
  };

  const handleZoomOut = (index: number) => {
    setContainerWidths((prev) => {
      const currentWidth = prev[index] || 100;
      const newWidth = Math.max(currentWidth - 5, 30) as ExtendedMediaWidth;
      return {
        ...prev,
        [index]: newWidth,
      };
    });
  };

  const handleReset = (index: number) => {
    setContainerWidths((prev) => ({
      ...prev,
      [index]: (media?.[index].width || 50) as ExtendedMediaWidth,
    }));
  };

  if (media?.length === 0) return null;

  if (hasBreakPage) {
    return (
      <Container className="mediaContainer">
        {media?.map((item, index) => (
          <PrintableImageWrapper
            key={item.url}
            imgWidth={
              containerWidths[index] || (item.width as ExtendedMediaWidth)
            }
          >
            <PrintableImageContainer className="printable-image-container">
              <ImageControls>
                <ImageControlButton onClick={() => handleZoomIn(index)}>
                  <Icon icon="ZoomInIcon" size="S" />
                </ImageControlButton>
                <ImageControlButton onClick={() => handleZoomOut(index)}>
                  <Icon icon="ZoomOutIcon" size="S" />
                </ImageControlButton>
                <ImageControlButton onClick={() => handleReset(index)}>
                  <Icon icon="RestartIcon" size="S" />
                </ImageControlButton>
              </ImageControls>
              <ImageWrapper>
                <PrintableImage
                  ref={(el) => (imageRefs.current[index] = el)}
                  key={item.url}
                  src={item.url}
                  alt={item.name}
                  className="printableImage"
                />
              </ImageWrapper>
            </PrintableImageContainer>
          </PrintableImageWrapper>
        ))}
      </Container>
    );
  }

  return (
    <Container className="mediaContainer">
      {media?.map((item, index) =>
        item.type === 'Photo' ? (
          <ImageContainer
            imgWidth={item.width}
            imgHeight={imageRefs[index]?.clientHeight}
          >
            <ZoomIcon>
              <Icon icon="ZoomInIcon" iconColor="white" size="XL" />
            </ZoomIcon>
            <StateLayer className="image-stateLayer" />
            <ModalOverlay
              element={
                <MediaModal
                  src={item.url}
                  alt={item.name}
                  onClose={closeModal}
                />
              }
              isOpen={modalStates[index] || false}
              onClose={closeModal}
            />
            <Image
              ref={(el) => (imageRefs[index] = el)}
              key={item.url}
              src={item.url}
              alt={item.name}
              onClick={(e) => openModal(e, index)}
            />
          </ImageContainer>
        ) : (
          <PreprPlayerWrapper width={item.width} id="prepr-video">
            <PreprPlayer
              metadata={{
                videoId: item.id,
                videoTitle: item.name,
              }}
              playbackId={item.playbackId!}
            />
          </PreprPlayerWrapper>
        )
      )}
    </Container>
  );
}
