import styled from '@emotion/styled';

import {
  BTC_10_WHEN_HOW,
  BTC_1_ASKING_QUESTIONS,
  BTC_2_AUTONOMY,
  BTC_3_CONSOLIDATE,
  BTC_4_DEFRONTING,
  BTC_5_ENGAGING_THINKING_TASK,
  BTC_6_HINTS_EXTENSIONS,
  BTC_7_TAKE_NOTES,
  BTC_8_VISIBLY_RANDOM_GROUPS,
  BTC_9_VPNS,
  C1_COUNSELOR_IMG,
  C2_COUNSELOR_IMG,
} from '@innovamat/glimmer-assets';

import { t as i18nT } from 'i18next';
import type { ReactElement } from 'react';
import { useSmartGuides } from '../../../context/SmartGuidesProvider';
import { PrintableBody2 } from '../../../printable-styles/printable-typography';
import type { MediaType } from '../../../types/Media';
import type { TableContentType } from '../../../types/Table';
import { PreprInnerHtml } from '../../PreprInnerHtml';
import { BaseTip, Text } from '../base-tip/base-tip';

type ImageAsset = {
  image: Image;
};

type IconAsset = {
  icons: ReactElement[];
};

type Image = {
  src: string;
};

const COUNSELOR_MEDIA: Record<string, ImageAsset> = {
  c1: {
    image: C1_COUNSELOR_IMG as unknown as Image,
  },
  c2: {
    image: C2_COUNSELOR_IMG as unknown as Image,
  },
};

const SIGNED_TIP_ICON: Record<string, IconAsset> = {
  ST1: {
    icons: [
      <BTC_1_ASKING_QUESTIONS
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.asking-questions')}
      />,
      <BTC_2_AUTONOMY
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.autonomy')}
      />,
      <BTC_3_CONSOLIDATE
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.consolidate')}
      />,
      <BTC_4_DEFRONTING
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.defronting')}
      />,
      <BTC_5_ENGAGING_THINKING_TASK
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.engaging-thinking-task')}
      />,
      <BTC_6_HINTS_EXTENSIONS
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.hints-extensions')}
      />,
      <BTC_7_TAKE_NOTES
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.take-notes')}
      />,
      <BTC_8_VISIBLY_RANDOM_GROUPS
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.visibly-random-groups')}
      />,
      <BTC_9_VPNS
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.vpns')}
      />,
      <BTC_10_WHEN_HOW
        role="img"
        aria-label={i18nT('smartguide.counselor.btc.when-how')}
      />,
    ],
  },
};

type Props = {
  type: string;
  children: string;
  icon?: number;
  isPrintable?: boolean;
  media?: MediaType[] | null;
  tables?: TableContentType[];
  counselor?: keyof typeof COUNSELOR_MEDIA;
};

const FlexContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const CounselorContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  min-height: 140px;
`;

const CounselorText = styled(PrintableBody2)<{
  position: 'top' | 'bottom';
}>`
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
  font-style: italic;
  font-size: 12px;
  order: ${({ position }) => (position === 'top' ? 0 : 2)};

  @media print {
    font-size: 10px;
    color: ${({ theme }) =>
      theme.tokens.color.alias.cm.text['text-subtle'].value};
    font-style: italic;
  }
`;

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  order: 1;

  @media print {
    width: 70px;
    height: 70px;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  order: 1;
`;

const IconOverlay = styled.div`
  position: absolute;
  top: 61px;
  right: 0;
`;

function SignedTip({
  type,
  children,
  icon,
  isPrintable,
  media,
  tables,
  counselor,
}: Props): JSX.Element {
  const { t } = useSmartGuides();

  const iconPosition = icon ? icon - 1 : 0;
  const hasValidIcon =
    counselor && icon && SIGNED_TIP_ICON[type]?.icons[iconPosition];

  return (
    <BaseTip
      summary={t(`digitalguides.signedTip.${type}`)}
      isPrintable={isPrintable}
      media={media}
      tables={tables}
    >
      <FlexContainer>
        <Text>
          <PreprInnerHtml text={children} />
        </Text>
        {!isPrintable && counselor && (
          <CounselorContainer>
            <CounselorText position={hasValidIcon ? 'top' : 'bottom'}>
              {t(`smartguide.counselor.${counselor}`)}
            </CounselorText>
            <ImageContainer>
              <StyledImage
                src={
                  COUNSELOR_MEDIA[counselor].image.src ||
                  (COUNSELOR_MEDIA[counselor].image as unknown as string)
                }
                alt={t(`smartguide.counselor.${counselor}`)}
              />
              {hasValidIcon && (
                <IconOverlay>
                  {SIGNED_TIP_ICON[type].icons[iconPosition]}
                </IconOverlay>
              )}
            </ImageContainer>
          </CounselorContainer>
        )}
      </FlexContainer>
    </BaseTip>
  );
}

export { SignedTip };
