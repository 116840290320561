import type { MPTag } from '../components/math-practices';

const getMathPracticeText = (tag: string) => {
  return `smartguides.mp_${tag.split(' ')[1]}`;
};

export function mapNewMathPractices(mathPractices?: string[]): MPTag[] {
  if (!mathPractices) {
    return [];
  }
  return mathPractices.map((item: any) => {
    return {
      tag: item,
      tooltipKey: getMathPracticeText(item),
    };
  });
}
