import { Region } from '@innovamat/localization';
import { standards } from '@innovamat/radiance-utils';
import { BigIdea, StandardContent } from '@innovamat/resource-viewer';
import { mapBigIdeas } from '../../../mappers/map-big-ideas';
import { mapNewMathPractices } from '../../../mappers/map-new-math-practices';
import { mapNewStandardContent } from '../../../mappers/map-new-standard-content';
import { Stage } from '../../../types/Stage';
import { HighlightSpan } from '../../components/HighlightSpan';
import type { IconRecord } from '../../types/icons';
import { PrepElement } from '../../types/prepr';
import { getData } from '../../utils/api';
import { getItemsFromLang } from '../../utils/common';
import {
  AskAndObserve,
  AskAndObserveData,
  AskAndObserveItem,
  AskAndObserveTableCell,
  AskAndObserveTableStructure,
  Challenges,
} from './types/challenges';
import { EarlyYearDigitalGuide } from './types/earlyYearDigitalGuide';

const SUBTITLES = {
  '1': 'digitalguides.ey.subtitle.symbolic',
  '2': 'digitalguides.ey.subtitle.construction',
  '3': 'digitalguides.ey.subtitle.art',
  '4': 'digitalguides.ey.subtitle.observation',
  '5': 'digitalguides.ey.subtitle.table',
  '6': 'digitalguides.ey.subtitle.board',
};

const SUBTITLE_CLASSROOM_LIFE = {
  '1': 'digitalguides.ey.class_life.stories',
  '2': 'digitalguides.ey.class_life.board_games',
  '3': 'digitalguides.ey.class_life.daily_moments',
  '4': 'digitalguides.ey.class_life.short_moments',
};

export const DIMENSIONS = {
  '1': 'digitalguides.dimensions.problem_solving',
  '2': 'digitalguides.dimensions.reasoning_and_proof',
  '3': 'digitalguides.dimensions.connections',
  '4': 'digitalguides.dimensions.communication_and_representation',
};

export const EARLY_YEAR_CONTENT_BLOCKS = {
  '1': 'digitalguides.blocks.numbers',
  '2': 'digitalguides.blocks.space_and_shape',
  '3': 'digitalguides.blocks.measurement',
  '4': 'digitalguides.blocks.change_and_relationships',
  '5': 'digitalguides.blocks.statistics',
};

export const EARLY_YEAR_CONTENT_BLOCKS_ICONS: IconRecord = {
  '1': 'Numeration',
  '2': 'Cube',
  '3': 'Measure',
  '4': 'Tetris',
  '5': 'Luck',
};

const mapChallenges = async (
  challenges: any,
  locale: string,
  region: Region
): Promise<Challenges> => {
  const challengesIds = challenges.items?.flatMap(
    (item: any) => item.items.challenge.items
  );

  const mappedChallenges = await Promise.all(
    challengesIds.map((c: any) => getData(c.id, 'items{image{cdn_files}}'))
  );

  return {
    ...challenges,
    items: mappedChallenges.map((c: any) => {
      const challenge = getItemsFromLang(c.items, locale, region);

      return {
        id: c.id,
        ...challenge,
      };
    }),
  };
};

export const mapSubtitle = (
  summary: PrepElement,
  preprType: string
): PrepElement => ({
  ...summary,
  body:
    preprType === 'earlyYear'
      ? SUBTITLES[summary.body as keyof typeof SUBTITLES]
      : SUBTITLE_CLASSROOM_LIFE[
          summary.body as keyof typeof SUBTITLE_CLASSROOM_LIFE
        ],
});

const mapStandards = (standards: any, region: Region) => {
  if (region === 'US-EDR' && standards?.items) {
    return standards.items.map((s: any) => s.body);
  } else {
    return null;
  }
};

export const earlyYearDigitalGuideMapper = async (
  response: any,
  locale: string,
  region: Region,
  standardContent?: StandardContent[],
  mathPractices?: string[],
  bigIdeas?: BigIdea[],
  orgStandard?: string
) => {
  const items = getItemsFromLang(response.items, locale, region);

  const stage = Stage.EarlyYear;
  const isCaliforniaStandard = orgStandard === standards.CALIFORNIA;
  const isBubbleTreeStandard = orgStandard === standards.BUBBLE_TREE;

  const digitalGuide: EarlyYearDigitalGuide = {
    challenges: await mapChallenges(items.challenges, locale, region),
    contentBlocks: isBubbleTreeStandard && items.content_blocks,
    subtitle: mapSubtitle(items.subtitle, 'earlyYear'),
    title: items.title,
    summary: items.summary,
    standards: mapStandards(items.standards, region),
    dimensions: isBubbleTreeStandard && items.dimensions,
    variations: items.variations,
    genericAsset: items.generic_image,
    thumbnailVideo: items.thumbnail_video,
    dimensionsDisclaimer: isBubbleTreeStandard && items.dimensions_disclaimer,
    type: 'digitalGuide',
    preprType: 'earlyYear',
    newStandardContent: mapNewStandardContent({
      data: {} as any,
      stage,
      standardContent,
      isCaliforniaStandard,
    }),
    newMathPractices: mapNewMathPractices(mathPractices),
    bigIdeas: mapBigIdeas(bigIdeas),
  };

  return digitalGuide;
};

export const earlyYearClassroomLifeDigitalGuideMapper = async (
  response: any,
  locale: string,
  region: Region,
  standardContent?: StandardContent[],
  mathPractices?: string[],
  bigIdeas?: BigIdea[],
  orgStandard?: string
) => {
  const items = getItemsFromLang(response.items, locale, region);

  const stage = Stage.EarlyYear;
  const isCaliforniaStandard = orgStandard === standards.CALIFORNIA;
  const isBubbleTreeStandard = orgStandard === standards.BUBBLE_TREE;

  const digitalGuide: EarlyYearDigitalGuide = {
    challenges: await mapChallenges(items.challenges, locale, region),
    contentBlocks: isBubbleTreeStandard && items.content_blocks,
    subtitle: mapSubtitle(items.subtitle, 'earlyYearClassroomLife'),
    title: items.title,
    summary: items.summary,
    standards: mapStandards(items.standards, region),
    dimensions: isBubbleTreeStandard && items.dimensions,
    variations: items.variations,
    genericAsset: items.generic_image,
    thumbnailVideo: items.thumbnail_video,
    dimensionsDisclaimer: isBubbleTreeStandard && items.dimensions_disclaimer,
    type: 'digitalGuide',
    preprType: 'earlyYearClassroomLife',
    newStandardContent: mapNewStandardContent({
      data: {} as any,
      stage,
      standardContent,
      isCaliforniaStandard,
    }),
    newMathPractices: mapNewMathPractices(mathPractices),
    bigIdeas: mapBigIdeas(bigIdeas),
  };

  return digitalGuide;
};

const extractFirstColumnItems = (
  input: AskAndObserveData[]
): AskAndObserveData[] => {
  return input?.filter((i) => !i.items);
};

const extractSecondColumnItems = (
  input: AskAndObserveData[]
): AskAndObserveItem[] => {
  let row = -1;
  const secondColumnItems: AskAndObserveItem[] = [];

  input?.forEach((i) => {
    if (i.items && i.items?.question) {
      secondColumnItems.push({ ...i.items?.question, row: row });
    }
    if (!i.items) {
      row++;
    }
  });

  return secondColumnItems;
};

const extractThirdColumnContent = (
  input: AskAndObserveData[],
  region: string,
  isPrintable?: boolean
): (string | JSX.Element)[][] => {
  const thirdColumnItemsGrouped: (string | JSX.Element)[][] = [];
  let currentGroup: (string | JSX.Element)[] = [];

  input.forEach((item) => {
    if (item.body || item.items?.question) {
      if (currentGroup.length > 0) {
        thirdColumnItemsGrouped.push(currentGroup);
      }
      currentGroup = [];
    }

    const tooltip = item.items?.tooltip?.body;
    const dimension = item.items?.dimension;
    const block = item.items?.block;

    if (block) {
      if ((region !== 'US' && !isPrintable) || !isPrintable) {
        currentGroup.push(
          <HighlightSpan
            type="block"
            text={tooltip || ''}
            tooltip={
              EARLY_YEAR_CONTENT_BLOCKS[
                (block.body as keyof typeof EARLY_YEAR_CONTENT_BLOCKS) || '1'
              ]
            }
            id={0}
          />
        );
      } else {
        currentGroup.push(tooltip!);
      }
    } else if (dimension) {
      currentGroup.push(
        <HighlightSpan
          type="dimension"
          text={tooltip || ''}
          tooltip={
            DIMENSIONS[(dimension.body as keyof typeof DIMENSIONS) || '1']
          }
          id={(dimension.body && +dimension.body) || 0}
        />
      );
    }
  });

  if (currentGroup.length > 0) {
    thirdColumnItemsGrouped.push(currentGroup);
  }

  return thirdColumnItemsGrouped;
};

const mapRows = (
  firstColumnItems: AskAndObserveData[],
  secondColumnItems: AskAndObserveItem[],
  thirdColumnItemsGrouped: (string | JSX.Element)[][],
  hiddenColumn: boolean
): AskAndObserveTableCell[][] => {
  const rows: AskAndObserveTableCell[][] = [];

  let realIndex = -1;

  firstColumnItems.forEach((firstItem, index) => {
    const rowSecondItems = secondColumnItems.filter(
      (item) => item.row === index
    );

    realIndex++;

    rows.push(
      [
        {
          isHeader: false,
          text: firstItem.body || '',
        },
        !hiddenColumn
          ? {
              isHeader: false,
              text: rowSecondItems[0]?.body,
              className: 'bold',
            }
          : undefined,
        {
          isHeader: false,
          text: (
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
            >
              {thirdColumnItemsGrouped[realIndex]?.map((item, idx) =>
                typeof item === 'string' ? <p key={idx}>{item}</p> : item
              )}
            </div>
          ),
        },
      ].filter(Boolean) as AskAndObserveTableCell[]
    );

    if (rowSecondItems?.length > 1) {
      rowSecondItems.slice(1).forEach((secondItem) => {
        realIndex++;
        rows.push([
          {
            isHeader: false,
            text: '',
          },
          {
            isHeader: false,
            text: secondItem?.body!,
            className: 'bold',
          },
          {
            isHeader: false,
            text: (
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}
              >
                {thirdColumnItemsGrouped[realIndex]?.map((item, idx) =>
                  typeof item === 'string' ? <p key={idx}>{item}</p> : item
                )}
              </div>
            ),
          },
        ]);
      });
    }
  });

  return rows;
};

const mapHeaders = (hiddenColumn: boolean): AskAndObserveTableCell[] => {
  return hiddenColumn
    ? [
        { isHeader: true, text: 'digitalguides.ey.observe' },
        { isHeader: true, text: 'digitalguides.ey.interpret' },
      ]
    : [
        { isHeader: true, text: 'digitalguides.ey.observe' },
        { isHeader: true, text: 'digitalguides.ey.ask' },
        { isHeader: true, text: 'digitalguides.ey.interpret' },
      ];
};

const mapPrintableTables = (
  firstColumnItems: AskAndObserveData[],
  secondColumnItems: AskAndObserveItem[],
  thirdColumnItemsGrouped: (string | JSX.Element)[][],
  hiddenColumn: boolean
): AskAndObserveTableStructure[] => {
  const tables: AskAndObserveTableStructure[] = [];
  let currentTableRows: AskAndObserveTableCell[][] = [];

  firstColumnItems.forEach((firstItem, index) => {
    const rowSecondItems = secondColumnItems.filter(
      (item) => item.row === index
    );

    if (!hiddenColumn) {
      currentTableRows.push(
        [
          {
            isHeader: true,
            text: 'digitalguides.ey.observe',
            colSpan: hiddenColumn ? 1 : 2,
          },
        ],
        [
          {
            isHeader: false,
            text: firstItem.body || '',
            colSpan: hiddenColumn ? 1 : 2,
          },
        ]
      );
    } else {
      currentTableRows.push(
        [
          {
            isHeader: true,
            text: 'digitalguides.ey.observe',
          },
          {
            isHeader: true,
            text: 'digitalguides.ey.interpret',
          },
        ],
        [
          {
            isHeader: false,
            text: firstItem.body || '',
          },

          {
            isHeader: false,
            text: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                {thirdColumnItemsGrouped[index]?.map((item, idx) =>
                  typeof item === 'string' ? <p key={idx}>{item}</p> : item
                )}
              </div>
            ),
          },
        ]
      );
    }

    if (!hiddenColumn) {
      currentTableRows.push(
        [
          {
            isHeader: true,
            text: 'digitalguides.ey.ask',
          },
          { isHeader: true, text: 'digitalguides.ey.interpret' },
        ],
        [
          {
            isHeader: false,
            text: rowSecondItems[0]?.body!,
            className: 'bold',
          },
          {
            isHeader: false,
            text: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                {thirdColumnItemsGrouped[index]?.map((item, idx) =>
                  typeof item === 'string' ? <p key={idx}>{item}</p> : item
                )}
              </div>
            ),
          },
        ]
      );
    }
    if (rowSecondItems.length > 1) {
      rowSecondItems.slice(1).forEach((secondItem, secondItemIndex) => {
        currentTableRows.push(
          [
            {
              isHeader: false,
              text: '',
              colSpan: hiddenColumn ? 1 : 2,
            },
          ],
          [
            {
              isHeader: false,

              text: secondItem?.body!,
              className: 'bold',
            },
            {
              isHeader: false,

              text: (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                  }}
                >
                  {thirdColumnItemsGrouped[secondItemIndex]?.map((item, idx) =>
                    typeof item === 'string' ? <p key={idx}>{item}</p> : item
                  )}
                </div>
              ),
            },
          ]
        );
      });
    }

    tables.push({
      title: 'Ask and Observe',
      columnPercentage: [50, 50],
      rows: currentTableRows,
    });
    currentTableRows = [];
  });

  return tables;
};

export const mapAskAndObserve = (
  askandobserve: AskAndObserve,
  region: string,
  isPrintable?: boolean
): AskAndObserveTableStructure[] => {
  const input = askandobserve?.items;

  if (!input) return [];

  const firstColumnItems = extractFirstColumnItems(input);
  const secondColumnItems = extractSecondColumnItems(input);
  const thirdColumnItemsGrouped = extractThirdColumnContent(
    input,
    region,
    isPrintable
  );

  const hiddenColumn = !secondColumnItems?.some((i) => i.body);

  if (isPrintable) {
    return mapPrintableTables(
      firstColumnItems,
      secondColumnItems,
      thirdColumnItemsGrouped,
      hiddenColumn
    );
  }

  const columnPercentage = hiddenColumn ? [50, 50] : [33, 33, 33];
  const rows = mapRows(
    firstColumnItems,
    secondColumnItems,
    thirdColumnItemsGrouped,
    hiddenColumn
  );
  rows.unshift(mapHeaders(hiddenColumn));

  return [
    {
      title: 'Ask and Observe',
      columnPercentage: columnPercentage,
      rows: rows,
    },
  ];
};
