import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {
  useOrganizationQuery,
  useStudentQuery,
} from '@innovamat/glow-api-client';
import {
  Roles,
  TokenData,
  inlineManual,
  roles,
  storage,
} from '@innovamat/radiance-utils';
import { detectIsInIOS, detectIsInWebview } from '@innovamat/webview-bridge';

import type { CookiesUser, User } from '../types';

import { LoginResponse } from '@innovamat/social-login';
import { useEnvVariables } from '../../env-variables-manager';
import {
  type StudentViewLocation,
  useStudentView,
} from '../hooks/use-student-view';
import {
  getUserFromCookies,
  mapUserToInlineManualUser,
} from '../utils/user-token';

export type UserManagementProps = {
  children: React.ReactNode;
};

export const USE_USER_KEY = ['User'];

export type UserManagementValue = {
  user: User | undefined;
  isRole: {
    Admin: boolean;
    Advisor: boolean;
    Content: boolean;
    DistrictAdmin: boolean;
    Free: boolean;
    Internal: boolean;
    Lead: boolean;
    Manager: boolean;
    Owner: boolean;
    Parent: boolean;
    Student: boolean;
    Teacher: boolean;
  };
  isLoggedIn: boolean;
  tokenInfo: TokenData | undefined;
  onClearUserSession: () => void;
  onForceUpdateUserValues: (user: Partial<User>) => void;
  isStudentViewActive: boolean;
  onToggleStudentView: (location?: StudentViewLocation) => void;
  onDisableStudentView: () => void;
  onSetUserLogin: (loginResponse: LoginResponse) => void;
};

const UserManagementContext = createContext<UserManagementValue>({
  user: undefined,
  isRole: {
    Admin: false,
    Advisor: false,
    Content: false,
    DistrictAdmin: false,
    Free: false,
    Internal: false,
    Lead: false,
    Manager: false,
    Owner: false,
    Parent: false,
    Student: false,
    Teacher: false,
  },
  isLoggedIn: false,
  tokenInfo: undefined,
  onClearUserSession: () => {},
  onForceUpdateUserValues: () => {},
  isStudentViewActive: false,
  onToggleStudentView: () => {},
  onDisableStudentView: () => {},
  onSetUserLogin: () => {},
});

function UserManagementProvider({
  children,
}: UserManagementProps): JSX.Element {
  const { WEBAPP } = useEnvVariables();
  const [user, setUser] = useState<CookiesUser | undefined>(getUserFromCookies);

  const { data: orgData, isSuccess: isOrgSuccess } = useOrganizationQuery(
    { id: user?.school! },
    { staleTime: Infinity, enabled: !!user?.school }
  );

  const { data: studentData } = useStudentQuery(
    { id: user?.id! },
    { enabled: roles.hasStudentRole(user?.roles), staleTime: Infinity }
  );

  const queryClient = useQueryClient();

  const enabledSetUser =
    (user?.school && isOrgSuccess) ||
    Boolean(user?.crmId) ||
    roles.hasParentRole(user?.roles);

  const query = useQuery({
    queryKey: USE_USER_KEY,
    queryFn: async () => {
      const user = getUserFromCookies();

      if (!enabledSetUser || !user) return;

      const newUser: User = {
        id: user.id,
        crmId: user.crmId,
        email: user.email,
        roles: user.roles,
        organizationName: '',
        organizationId: '',
        organization: undefined,
        region: user.region,
        physicalRegion: user.region,
        locale: user.locale,
        name: user.name,
        fullName: user.fullName,
        familyName: user.familyName,
      };

      if (roles.hasStudentJuniorRole(user.roles)) {
        window.location.href = WEBAPP;
      }

      if (roles.hasParentRole(user.roles)) {
        return newUser;
      }

      if (!user.school) {
        if (!user.crmId) {
          throw new Error('No school or crmId found in user');
        }

        // newUser.organizationName = await getSchoolName(newUser.crmId!);
      } else {
        if (!orgData?.organization) {
          throw new Error('No organization data found');
        }
        const org = orgData.organization!;
        newUser.organizationName = org.name!;
        newUser.organizationId = org.id!;
        newUser.organization = {
          id: org.id!,
          location: org.location!,
          academicYearId: org.academicYearId,
          currency: org.currency!,
          platformEnabled: org.platformEnabled!,
          name: org.name!,
          standard: org.standard!,
        };

        if (org.region !== org.operativeRegion) {
          if (org.operativeRegion !== 'MX-PRF') {
            newUser.locale = org.language;
          }
          newUser.region = org.operativeRegion!;
          newUser.physicalRegion = org.region;
        }

        storage.region.set(newUser.region);
        storage.language.set(newUser.locale);
      }

      if (roles.hasStudentRole(user.roles)) {
        const student = studentData?.student;
        if (!student) {
          throw new Error('No organization data found');
        }
        newUser.studentData = {
          classroomId: student.classroomId!,
          classroomName: student.classroomName!,
          courseId: student.courseId!,
          courseOrder: student.courseOrder!,
        };
      }

      const isInWebview = detectIsInWebview();
      const isInIOS = detectIsInIOS();

      const inlineUser = mapUserToInlineManualUser(
        newUser,
        isInIOS,
        isInWebview
      );
      inlineManual.initOrUpdateInlineManualPlayer(inlineUser);

      return newUser;
    },
    enabled: !!enabledSetUser,
    staleTime: Infinity,
  });

  const handleUpdateRoles = useCallback(
    (roles: Roles[]) => {
      queryClient.setQueryData<User | undefined>(USE_USER_KEY, (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          roles,
        };
      });

      queryClient.refetchQueries({ queryKey: ['SitemapTree'] });
    },
    [queryClient]
  );

  const { isStudentViewActive, onToggleStudentView, onDisableStudentView } =
    useStudentView({
      onUpdateRoles: handleUpdateRoles,
      roles: user?.roles || [],
    });

  const onClearUserSession = (): void => {
    storage.clear();
    queryClient.removeQueries();
    inlineManual.deleteInlineManualUser();
    setUser(undefined);
  };

  const isRole = useMemo(
    () => ({
      Admin: roles?.hasAdminRole(query.data?.roles),
      Advisor: roles?.hasAdvisorRole(query.data?.roles),
      Content: roles?.hasContentAdminRole(query.data?.roles),
      DistrictAdmin: roles?.hasDistrictAdminRole(query.data?.roles),
      Free: roles?.hasFreeRole(query.data?.roles),
      Internal: roles?.hasInternalRole(query.data?.roles),
      Lead: roles?.hasLeadRole(query.data?.roles),
      Manager: roles?.hasManagerRoles(query.data?.roles),
      Owner: roles?.hasOwnerRole(query.data?.roles),
      Parent: roles?.hasParentRole(query.data?.roles),
      Student: roles?.hasStudentRole(query.data?.roles),
      Teacher: roles?.hasTeacherRole(query.data?.roles),
    }),
    [query.data?.roles]
  );

  const onForceUpdateUserValues = (user: Partial<User>) => {
    queryClient.setQueryData<User>(USE_USER_KEY, (prevUser) => {
      if (!prevUser) return undefined;
      return { ...prevUser, ...user };
    });
  };

  const onSetUserLogin = (loginResponse: LoginResponse) => {
    storage.tokenInfo.set(loginResponse);
    setUser(getUserFromCookies());
  };

  const tokenInfo = storage.tokenInfo.get();
  const isLoggedIn = Boolean(tokenInfo?.access_token || query.data?.id);

  const value: UserManagementValue = {
    user: query.data,
    isRole,
    isLoggedIn,
    onForceUpdateUserValues,
    tokenInfo,
    onClearUserSession,
    isStudentViewActive,
    onToggleStudentView,
    onDisableStudentView,
    onSetUserLogin,
  };

  return (
    <UserManagementContext.Provider value={value}>
      {children}
    </UserManagementContext.Provider>
  );
}

function useUser(): UserManagementValue {
  const context = useContext(UserManagementContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserManagementProvider');
  }
  return context;
}

export { UserManagementProvider, useUser };
