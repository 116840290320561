import type { Theme } from '@emotion/react';

export function getColorByStage(
  courseOrder: number,
  theme: Theme
): string | undefined {
  const stageToken = theme.tokens.color.specific.stage;

  if (courseOrder <= 5) {
    return stageToken['early-years'].value;
  }
  if (courseOrder >= 6 && courseOrder <= 11) {
    return stageToken['primary'].value;
  }
  if (courseOrder >= 12 && courseOrder <= 17) {
    return stageToken['secondary'].value;
  }

  return undefined;
}
