import styled from '@emotion/styled';
import { theme } from '@innovamat/innova-components';
import { useDigitalGuides } from '../context';
import type { Variations } from '../templates/EarlyYear/types/variations';
import Heading from './Heading';
import UploadImage from './UploadImage';
import VariationItem from './VariatonItem';

type Props = {
  variations: Variations;
};

const Wrapper = styled.div`
  margin-top: 48px;
`;

const GridContainerWrapper = styled.div`
  max-width: ${theme.breakpoints.xl} !important;
  display: grid;
  grid-template-rows: repeat(1, minmax(0, 1fr));
  gap: 32px;

  // TODO: discuss with design what to do with this endpoint, xxl maybe?
  @media screen and (max-width: 7680px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.xl}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const GridColWrapper = styled.div`
  margin-top: 24px;
  :first-of-type {
    padding-left: 0 !important;
  }
`;

export default function VariationsBlock({ variations }: Props): JSX.Element {
  const { t, id } = useDigitalGuides();

  return (
    <Wrapper>
      <Heading>{t('digitalguides.ey.variations')}</Heading>
      <GridContainerWrapper>
        {variations?.items?.map((variation, index) => (
          <GridColWrapper key={index}>
            <VariationItem
              title={variation.items.title?.body}
              description={variation.items.description?.body}
              image={variation.items.image?.items[0].cdn_files.items[0].url}
            />
          </GridColWrapper>
        ))}
        <GridColWrapper>
          <VariationItem
            title={t('digitalguides.ey.variations.idea')}
            description={t('digitalguides.ey.variations.question')}
            image={<UploadImage sessionId={id} />}
          />
        </GridColWrapper>
      </GridContainerWrapper>
    </Wrapper>
  );
}
