import { NAV_TYPE, useCurrentNavigationValues } from '@innovamat/ga-features';
import { roles, stages } from '@innovamat/radiance-utils';
import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import type { ContentResource } from '../../contents';
import { useUser } from '../../user-management';
import UnblockedAppletsDrawer from '../components/unblocked-applets-drawer';

type Props = {
  children: ReactNode;
};

type Values = {
  isDrawerOpen: boolean;
  resourceSelected: ContentResource | undefined;
  onCloseDrawer: () => void;
  onOpenDrawer: (resource: ContentResource) => void;
  showLinkToOpenDrawer: ({
    courseOrder,
    resource,
  }: {
    courseOrder: number | null | undefined;
    resource: ContentResource;
  }) => boolean;
};

const UnblockedAppletsContext = createContext<Values | undefined>(undefined);

export const UnblockedAppletsDrawerProvider = ({
  children,
}: Props): JSX.Element => {
  const { user, isStudentViewActive } = useUser();
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [resourceSelected, setResourceSelected] = useState<
    ContentResource | undefined
  >(undefined);
  const { navType } = useCurrentNavigationValues();

  const showLinkToOpenDrawer = ({
    courseOrder,
    resource,
  }: {
    courseOrder: number | null | undefined;
    resource: ContentResource;
  }): boolean => {
    const is4ESO = courseOrder === 15;
    const isClassroomSession =
      resource.type === 'session' &&
      Boolean(resource.code) &&
      resource.is_completable;

    if (navType !== NAV_TYPE.Classroom) return false;

    if (
      (!roles.hasTeacherRole(user?.roles) &&
        !roles.hasAdvisorRole(user?.roles)) ||
      isStudentViewActive
    )
      return false;
    if (!stages.isSecundaria(courseOrder)) return false;
    if (is4ESO) return false;
    if (!isClassroomSession) return false;

    return true;
  };

  const onOpenDrawer = (resource: ContentResource): void => {
    setResourceSelected(resource);
    setIsDrawerOpen(true);
  };

  const onCloseDrawer = (): void => {
    setResourceSelected(undefined);
    setIsDrawerOpen(false);
  };

  return (
    <UnblockedAppletsContext.Provider
      value={{
        isDrawerOpen,
        resourceSelected,
        onCloseDrawer,
        onOpenDrawer,
        showLinkToOpenDrawer,
      }}
    >
      {children}
      {isDrawerOpen && <UnblockedAppletsDrawer />}
    </UnblockedAppletsContext.Provider>
  );
};

export const useUnblockedAppletsDrawerContext = (): Values => {
  const context = useContext(UnblockedAppletsContext);
  if (context === undefined) {
    throw new Error(
      'useUnblockedAppletsDrawer must be used within a UnblockedAppletsDrawerProvider'
    );
  }
  return context;
};
