import styled from '@emotion/styled';

import { PrintableBody2 } from '../../printable-styles/printable-typography';
import { useDigitalGuides } from '../context';
import { ContentBlocks } from '../types/contentBlocks';
import { IconRecord } from '../types/icons';
import { getIconFromKey } from '../utils/mapIcons';
import Block from './Block';

type Props = {
  contentBlocks: ContentBlocks;
  items: Record<string, string>;
  contentIcons: IconRecord;
  isPrintable?: boolean;
};

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 8px;

  @media print {
    gap: 0 4px;
  }
`;

const Item = styled.div<{ isDisabled: boolean }>`
  font-family: ${({ theme }) => theme.typography.rubikMedium};
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  display: flex;
  padding: 8px;
  gap: 8px;
  align-items: center;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.dark['04'] : theme.colors.dark['01']};
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};

  svg path,
  rect {
    stroke: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.dark['04'] : theme.colors.dark['02']};
  }

  @media print {
    break-inside: avoid;
    gap: 4px;
    padding: 4px;
  }
`;

const IconWrapper = styled.div`
  @media print {
    svg {
      transform: scale(0.825);
    }
  }
`;

const ContentBlock = ({
  contentBlocks,
  items,
  contentIcons,
  isPrintable,
}: Props) => {
  const { t } = useDigitalGuides();
  const contentKeys = Object.keys(items);

  const getIsDisabled = (key: string) => {
    const ids =
      contentBlocks?.items?.map((item) => item.items.block.body) || [];
    return !ids.includes(key);
  };

  const getIcon = (key: string) => {
    const Icon = getIconFromKey(contentIcons, key);
    return Icon ? (
      <IconWrapper>
        <Icon />
      </IconWrapper>
    ) : null;
  };

  const getTranslations = (key: string) => items[key];

  const renderItems = (keys: string[]) => (
    <List>
      {keys.map((key) => {
        const isDisabled = getIsDisabled(key);

        if (isDisabled && isPrintable) return null;

        return (
          <Item key={key} isDisabled={isDisabled}>
            {getIcon(key)}
            <PrintableBody2>{t(getTranslations(key))}</PrintableBody2>
          </Item>
        );
      })}
    </List>
  );

  if (!contentBlocks) return null;

  return (
    <Block title={t('digitalguides.blocks')}>{renderItems(contentKeys)}</Block>
  );
};

export default ContentBlock;
