import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeType } from '@innovamat/glimmer-components';
import { useDimensionColors } from '../../hooks/useDimensionColors';
import { PrintableBody2 } from '../../printable-styles/printable-typography';
import type { Dimension } from '../../types/Dimension';

const TagContainer = styled.div<StyledProps>`
  border-radius: 20px;
  padding: 1.5px 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;

  ${({ bgColor, color }) => css`
    background-color: ${bgColor};
    color: ${color};
  `}

  ${({ isSupporting, theme }) =>
    isSupporting &&
    css`
      outline: 1px solid ${theme.tokens.color.global.neutral.neutral400.value};
      outline-offset: -1px;
    `}

  @media print {
    display: ${({ isEnabled }) => (isEnabled ? 'inline-block' : 'none')};
    padding: 3px 8px;
  }
`;

const TextContainer = styled(PrintableBody2)<{ color: string }>`
  white-space: nowrap;

  @media print {
    ${({ color }) => css`
      color: ${color};
    `}
  }
`;

type StyledProps = {
  bgColor: string;
  isEnabled?: boolean;
  isSupporting?: boolean;
};

type Props = {
  isEnabled?: boolean;
  dimension?: Dimension;
  text?: string;
  isSupporting?: boolean;
  bigIdea?: Dimension;
};

type GetColorProps = {
  isEnabled?: boolean;
  isSupporting?: boolean;
  dimension?: Dimension;
  getBackground: (dimension: Dimension) => string;
  getColorElement: (dimension: Dimension) => string;
  theme: ThemeType;
};

const getColors = ({
  isEnabled,
  isSupporting,
  dimension,
  getBackground,
  getColorElement,
  theme,
}: GetColorProps): { bgColor: string; color: string } => {
  if (!isEnabled) {
    return {
      bgColor: theme.tokens.color.alias.cm.bg['bg-neutral-subtle'].value,
      color: theme.tokens.color.alias.cm.text['text-disabled'].value,
    };
  }

  if (isSupporting) {
    return {
      bgColor: 'transparent',
      color: theme.tokens.color.alias.cm.text['text-subtle'].value,
    };
  }

  if (dimension !== undefined) {
    return {
      bgColor: getBackground(dimension),
      color: getColorElement(dimension),
    };
  }

  return {
    bgColor: theme.tokens.color.alias.cm.bg['bg-neutral-inverted-subtle'].value,
    color: 'white',
  };
};

export function Tag({
  isEnabled,
  dimension,
  text,
  isSupporting,
  bigIdea,
}: Props): JSX.Element {
  const {
    getDimensionBackground,
    getDimensionElement,
    getDimensionBackgroundForBigIdeas,
    getDimensionElementForBigIdeas,
  } = useDimensionColors();
  const theme = useTheme();

  const { bgColor, color } = getColors({
    isEnabled,
    isSupporting,
    dimension: dimension ?? bigIdea,
    getBackground: bigIdea
      ? getDimensionBackgroundForBigIdeas
      : getDimensionBackground,
    getColorElement: bigIdea
      ? getDimensionElementForBigIdeas
      : getDimensionElement,
    theme,
  });

  const className = isSupporting ? 'tag-container-supporting' : 'tag-container';

  return (
    <TagContainer
      className={className}
      bgColor={bgColor}
      isEnabled={isEnabled}
      isSupporting={isSupporting}
    >
      <TextContainer className="tag-text" color={color}>
        {text}
      </TextContainer>
    </TagContainer>
  );
}
