export const standards = {
  COMMON_CORE: 'COMMON_CORE',
  CALIFORNIA: 'CALIFORNIA',
  BUBBLE_TREE: 'BUBBLE_TREE',
  WEST_VIRGINIA: 'WEST_VIRGINIA',
  PENNSYLVANIA: 'PENNSYLVANIA',
} as const;

export type Standard = keyof typeof standards;

export const getStandardByRegion = (region: string): Standard => {
  if (region === 'US') return 'COMMON_CORE';
  if (region === 'US-EDR') return 'CALIFORNIA';
  return 'BUBBLE_TREE';
};
