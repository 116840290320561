import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { useUpdateEffect } from '@innovamat/hooks';
import type { CredentialResponse } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { createContext, useCallback, useMemo, useReducer } from 'react';
import { useCleverLogin } from '../hooks/use-clever-login';
import { useGoogleLogin } from '../hooks/useGoogleLogin';
import { useMsalLogin } from '../hooks/useMsalLogin';
import { useUsersLogin } from '../hooks/useUsersLogin';
import { useUsersLoginWebview } from '../hooks/useUsersLoginWebview';
import authReducer, { initState } from '../state/reducer';
import type { AuthDispatch, AuthState } from '../state/types';
import type { Credentials } from '../types/credentials';
import type {
  Autologin,
  LoginHookProps,
  LoginMethods,
  LoginRequest,
} from '../types/login';
import type { DecodedTokens } from '../types/token';
import { decodeTodenData } from '../utils/decode';

type Props = {
  children: React.ReactNode;
  autologin?: Autologin;
  credentials: Credentials;
};

type AuthProviderValue = AuthState & {
  isLoggedIn: boolean;
  onLogin: (type: LoginMethods, payload?: LoginRequest | string) => void;
  getDecodedTokenInfo: () => DecodedTokens | undefined;
  onGoogleAutologinFailure: () => void;
  onGoogleAutologinSuccess: (response: CredentialResponse) => void;
  autologin?: Autologin;
  credentials: Credentials;
};

export const AuthStateContext = createContext<AuthProviderValue | undefined>(
  undefined
);

export const AuthDispatchContext = createContext<AuthDispatch | undefined>(
  undefined
);

function LoginProvider({ children, autologin, credentials }: Props) {
  const [state, dispatch] = useReducer(authReducer, {
    ...initState,
  });

  const getProps = (loginMethod: LoginMethods): LoginHookProps => {
    return {
      dispatch,
      credentials,
      autologin: autologin?.[loginMethod.type],
    };
  };

  const onLoginUsers = useUsersLogin(getProps({ type: 'users' }));
  const onLoginUsersWebview = useUsersLoginWebview(
    getProps({ type: 'login_from_webview' })
  );

  const onLoginClever = useCleverLogin({
    ...getProps({ type: 'clever' }),
    usingRedirect: true,
  });

  const onLoginMsal = useMsalLogin({
    ...getProps({ type: 'msal' }),
    usingRedirect: true,
  });
  const { onGoogleSignIn, onGoogleAutologinFailure, onGoogleAutologinSuccess } =
    useGoogleLogin({ ...getProps({ type: 'google' }), usingRedirect: true });

  const onLogin = useCallback(
    (loginMethod: LoginMethods) => {
      if (loginMethod.type === 'users' && loginMethod.payload) {
        onLoginUsers(loginMethod.payload as LoginRequest);
      }

      if (loginMethod.type === 'msal') {
        onLoginMsal();
      }

      if (loginMethod.type === 'google') {
        onGoogleSignIn();
      }

      if (loginMethod.type === 'clever') {
        onLoginClever();
      }

      if (
        loginMethod.type === 'login_from_webview' &&
        loginMethod.payload &&
        typeof loginMethod.payload === 'string'
      ) {
        onLoginUsersWebview(loginMethod.payload);
      }
    },
    [onGoogleSignIn, onLoginMsal, onLoginUsers, onLoginUsersWebview]
  );

  /* 
    After getting the response with the token, we clear the reducer to
    avoid having to maintain two providers with the same token

    Note: in the future this provider will be the only that has the token
  */

  useUpdateEffect(() => {
    if (state.loginResponse) {
      dispatch({ type: 'CLEAR' });
    }
  }, [state.loginResponse]);

  const getDecodedTokenInfo = useCallback(() => {
    if (state.loginResponse) {
      return decodeTodenData(state.loginResponse);
    }
    return undefined;
  }, [state.loginResponse]);

  const value: AuthProviderValue = useMemo(
    () => ({
      ...state,
      isLoggedIn: Boolean(state.loginResponse?.access_token),
      onLogin,
      getDecodedTokenInfo,
      credentials,
      onGoogleAutologinFailure,
      onGoogleAutologinSuccess,
      autologin,
    }),
    [credentials, getDecodedTokenInfo, onLogin, state]
  );

  return (
    <AuthStateContext.Provider value={value}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export function AuthProvider({ children, credentials, autologin }: Props) {
  const msalInstance = useMemo(
    () =>
      new PublicClientApplication({
        auth: {
          clientId: `${credentials.msalClientId}`,
          authority: 'https://login.microsoftonline.com/common',
          redirectUri: window.location.origin,
        },
        cache: {
          cacheLocation: 'sessionStorage', // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
      }),
    [credentials.msalClientId]
  );

  return (
    <MsalProvider instance={msalInstance}>
      <GoogleOAuthProvider clientId={credentials.googleClientId}>
        <LoginProvider credentials={credentials} autologin={autologin}>
          {children}
        </LoginProvider>
      </GoogleOAuthProvider>
    </MsalProvider>
  );
}
