import { useMarkSessionMutation } from '@innovamat/glow-api-client';
import { inlineManual } from '@innovamat/radiance-utils';
import { useQueryClient } from '@tanstack/react-query';
import type { ContentResource } from '../../contents/types/page';
import { updateSessionDoneCache } from '../cache-actions/mark-session-done';
import { useSessionDone } from './session-done-provider';
import { askForApplets } from './use-get-applets';

export type MarkSessionMethodParams = {
  value: boolean;
  resource: ContentResource;
  dontOpenModal?: boolean;
  onMarkSessionFinished?: () => void;
};

type MarkSessionDoneHook = {
  onMarkSession: (params: MarkSessionMethodParams) => void;
  onToggleSolutions: (params: MarkSessionMethodParams) => void;
};

export function useMarkSessionDone(): MarkSessionDoneHook {
  const {
    classroomId,
    academicYearId,
    region,
    getIsNavigatingInCourseAndHasClassroom,
    onSetCurrentSession,
    currentSession,
    setEventData,
  } = useSessionDone();

  const queryClient = useQueryClient();
  const mutation = useMarkSessionMutation({
    onMutate: async ({ body }) => {
      updateSessionDoneCache({
        classroomId,
        queryClient,
        markedSessionBody: body,
      });
    },
  });

  const onMarkSession = ({
    value,
    resource,
    dontOpenModal,
    onMarkSessionFinished,
  }: MarkSessionMethodParams): void => {
    const isNavigatingInCourseAndHasClassroom =
      getIsNavigatingInCourseAndHasClassroom(
        resource.type,
        resource.is_completable
      );

    if (isNavigatingInCourseAndHasClassroom) {
      inlineManual.activateSessionDoneTopic();
      return;
    }

    if (!classroomId || !academicYearId) return;

    mutation.mutate(
      {
        body: {
          classroomId,
          resourceId: resource.id,
          done: value,
          solutionsEnabled: false,
          academicYearId,
        },
      },
      {
        onSuccess: () => {
          if (value && !dontOpenModal) {
            const showSessionDoneModal = askForApplets(resource, region);

            // Set resource in to provider, open modal and show applets & session feedback
            if (showSessionDoneModal) {
              onSetCurrentSession({ ...resource, solutions_enabled: false });
            }
          }

          setEventData(value ? 'mark_session_done' : 'mark_session_undone', {
            classroom_id: classroomId,
            session_id: resource.id,
          });

          onMarkSessionFinished?.();
        },
      }
    );
  };

  const onToggleSolutions = ({
    value,
    resource,
  }: MarkSessionMethodParams): void => {
    if (!classroomId || !academicYearId) return;

    mutation.mutate?.({
      body: {
        classroomId,
        resourceId: resource.id,
        done: true,
        solutionsEnabled: value,
        academicYearId,
      },
    });

    if (currentSession) {
      onSetCurrentSession({
        ...currentSession,
        solutions_enabled: value,
      });
    }
  };

  return { onMarkSession, onToggleSolutions };
}
