import styled from '@emotion/styled';
import { css } from '@emotion/react';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import type { IconType } from '@innovamat/glimmer-icons';
import { Icon } from '../icon';
import { getTypography } from '../typography';
import { LoadingAnimation } from '../loading-animation';
import { StateLayer } from '../../utils/common.styled';
import { useEffect, useState } from 'react';
import type { MouseEvent } from 'react';

const BUTTON_VARIANT = {
  ACCENT: 'accent',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
} as const;

export type ButtonVariant =
  (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT];

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'S' | 'M';
  variant?: ButtonVariant;
  leftIcon?: IconType;
  rightIcon?: IconType;
  loading?: boolean;
  dataTestId?: string;
  fill?: boolean;
  oneLine?: boolean;
  keepIconColor?: boolean;
  noRipple?: boolean;
}

const HideElement = styled.span<{ hide: boolean }>`
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
`;

const LoaderComponent = styled.span<{ hide: boolean }>`
  position: absolute;
  visibility: ${({ hide }) => (hide ? 'hidden' : '')};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled('button', {
  shouldForwardProp: (prop) =>
    prop !== 'fill' &&
    prop !== 'oneLine' &&
    prop !== 'keepIconColor' &&
    prop !== 'size',
})<{
  variant: ButtonVariant;
  fill?: boolean;
  oneLine?: boolean;
  keepIconColor?: boolean;
  size?: 'S' | 'M';
}>(({ theme, variant, fill, oneLine, keepIconColor, size }) => {
  const textColorToken = theme.tokens.color.alias.cm.text;
  const textColor = {
    [BUTTON_VARIANT.ACCENT]: textColorToken['text-inverted'].value,
    [BUTTON_VARIANT.SECONDARY]: textColorToken.text.value,
    [BUTTON_VARIANT.TERTIARY]: textColorToken.text.value,
  };

  const bgColorToken = theme.tokens.color.alias.cm.bg;
  const bgColor = {
    [BUTTON_VARIANT.ACCENT]: bgColorToken['bg-accent-inverted'].value,
    [BUTTON_VARIANT.SECONDARY]: bgColorToken['bg-neutral-subtle'].value,
    [BUTTON_VARIANT.TERTIARY]: 'transparent',
  };

  const bgColorDisabled = {
    [BUTTON_VARIANT.ACCENT]: bgColorToken['bg-disabled'].value,
    [BUTTON_VARIANT.SECONDARY]: 'transparent',
    [BUTTON_VARIANT.TERTIARY]: bgColorToken['bg-disabled'].value,
  };

  const iconColorToken = theme.tokens.color.alias.cm.icon;
  const iconColor = {
    [BUTTON_VARIANT.ACCENT]: iconColorToken['icon-inverted'].value,
    [BUTTON_VARIANT.SECONDARY]: iconColorToken['icon-default'].value,
    [BUTTON_VARIANT.TERTIARY]: iconColorToken['icon-default'].value,
  };

  return css`
    display: flex;
    align-items: center;
    padding: ${size === 'S' ? '8px 12px' : '12px 16px'};
    border-radius: 4px;
    gap: 8px;
    max-height: ${size === 'S' ? '32px' : '40px'};
    cursor: pointer;
    position: relative;
    border: none;
    width: ${fill ? '100%' : 'fit-content'};
    max-width: 100%;
    justify-content: center;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${oneLine ? 'nowrap' : ''};

    background-color: ${bgColor[variant]};
    color: ${textColor[variant]};
    outline: ${variant === BUTTON_VARIANT.SECONDARY
      ? `1px solid ${theme.tokens.color.alias.cm.border['border-subtle'].value}`
      : 'none'};
    outline-offset: -1px;
    svg {
      path {
        fill: ${keepIconColor ? undefined : iconColor[variant]};
      }
    }

    &:hover:enabled {
      outline: ${variant === BUTTON_VARIANT.SECONDARY
        ? `2px solid ${theme.tokens.color.alias.cm.border['border-subtle'].value}`
        : 'none'};
      outline-offset: -2px;
    }

    &:disabled {
      cursor: default;
      background-color: ${bgColorDisabled[variant]};
      color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};

      outline: ${variant === BUTTON_VARIANT.SECONDARY
        ? `1px solid ${theme.tokens.color.alias.cm.border['border-disabled'].value}`
        : 'none'};
      outline-offset: -1px;

      svg {
        path {
          fill: ${keepIconColor
            ? undefined
            : theme.tokens.color.alias.cm.icon['icon-disabled'].value};
        }
      }
    }

    .button-stateLayer {
      border-radius: 4px;
    }

    &:hover:enabled .button-stateLayer {
      background-color: ${theme.tokens.color.specific['state-layer'][
        'state-hover-darker'
      ].value};
    }

    &:active:enabled .button-stateLayer {
      background-color: ${theme.tokens.color.specific['state-layer'][
        'state-press-darker'
      ].value};
    }
  `;
});

const Text = styled.p<{ size?: 'S' | 'M' }>`
  ${({ theme, size }) =>
    size === 'M'
      ? getTypography(theme, 'Subtitle 2')
      : getTypography(theme, 'Body 2')};
  min-width: 0;
`;

const RippleContainer = styled.span<{ variant: ButtonVariant }>`
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 300ms ease-in;
  background-color: ${({ theme }) =>
    theme.tokens.color.specific['state-layer']['state-press-darker'].value};
  width: 40px;
  height: 40px;
  pointer-events: none;

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

const RippleWrapper = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 4px;
`;

export function Button({
  children,
  size = 'M',
  leftIcon,
  rightIcon,
  variant = BUTTON_VARIANT.ACCENT,
  loading,
  dataTestId,
  fill,
  oneLine,
  keepIconColor,
  onClick,
  noRipple,
  ...rest
}: ButtonProps): EmotionJSX.Element {
  const [ripple, setRipple] = useState(false);
  const [rippleCoords, setRippleCoords] = useState({ x: 0, y: 0, size: 0 });

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    const button = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - button.left - 40 / 2;
    const y = e.clientY - button.top - 40 / 2;
    const rippleSize = Math.min(button.width, button.height);

    setRippleCoords({ x, y, size: rippleSize });

    setRipple(true);

    if (onClick) {
      onClick(e);
    }
  };

  useEffect(() => {
    if (ripple) {
      setTimeout(() => setRipple(false), 300);
    }
  }, [ripple]);

  return (
    <StyledButton
      aria-label={children as string}
      variant={variant}
      data-testid={dataTestId || 'button'}
      fill={fill}
      oneLine={oneLine}
      keepIconColor={keepIconColor}
      size={size}
      onClick={loading ? undefined : handleButtonClick}
      {...rest}
    >
      <StateLayer className="button-stateLayer" />
      {!noRipple && (
        <RippleWrapper>
          {ripple && (
            <RippleContainer
              variant={variant}
              style={{
                left: `${rippleCoords.x}px`,
                top: `${rippleCoords.y}px`,
                width: `${rippleCoords.size}px`,
                height: `${rippleCoords.size}px`,
              }}
            />
          )}
        </RippleWrapper>
      )}
      {leftIcon && !loading && <Icon icon={leftIcon} size="M" />}
      <Text size={size} className="buttonText">
        <LoaderComponent hide={!loading} data-testid="loading-animation">
          <LoadingAnimation
            width={size === 'S' ? 60 : 80}
            height={size === 'S' ? 28 : 34}
          />
        </LoaderComponent>
        <HideElement hide={!!loading}>{children}</HideElement>
      </Text>
      {rightIcon && !loading && <Icon icon={rightIcon} size="M" />}
    </StyledButton>
  );
}

export default Button;
