import type { WeeklyReportDate } from '@innovamat/apollo-codegen';
import { dates } from '@innovamat/radiance-utils';
import i18next from 'i18next';

export const scoreLevels = {
  LOW_SCORE: 5,
  MEDIUM_SCORE: 7,
  MEDIUM_HIGH_SCORE: 9,
};

export const getFixedLocalizedScore = (score: number, fixedTo = 1) => {
  const language = i18next.language;

  if (score! >= 10) return Math.ceil(score);
  if (score! <= 0) return Math.ceil(score);
  return parseFloat(score.toFixed(fixedTo)).toLocaleString(
    language.replace('_', '-')
  );
};

export const getFixedLocalizedNumber = (
  number: number,
  fixedTo = 1
): string => {
  const language = i18next.language;
  return parseFloat(number.toFixed(fixedTo)).toLocaleString(
    language.replace('_', '-')
  );
};

export const getAppletReadableName = (
  name: string | undefined | null,
  sceneName: string,
  pack: number
) => {
  if (!name) return `${sceneName}.${pack}`;
  const replace = name.replace('<p>', '').replace('&nbsp;', '');
  const split = replace.split('-')[0];
  return split.trim();
};

export const isNullOrUndefined = (value: any) =>
  value === null || value === undefined;

export const getTrimester = (currentReport: string, t: any) => {
  switch (currentReport) {
    case 'trimester-1':
      return `${t('reports.firstTrimester.title')}`;
    case 'trimester-2':
      return `${t('reports.secondTrimester.title')}`;
    case 'trimester-3':
      return `${t('reports.thirdTrimester.title')}`;
    default:
      return '';
  }
};

export const getProcessedTeacherName = (
  firstName: string,
  lastName: string
): string => {
  return `(${firstName.charAt(0)}. ${lastName})`;
};

export const getFixedLocalizedScoreWithZero = (score: number, fixedTo = 1) => {
  const language = i18next.language;

  return score.toLocaleString(language.replace('_', '-'), {
    minimumFractionDigits: fixedTo,
    maximumFractionDigits: fixedTo,
  });
};

export const isDateMoreThanXDaysAgo = (
  dateStr: string,
  numOfDays: number
): boolean => {
  const date = new Date(dateStr);
  const currentDate = new Date();
  const daysDifference =
    (currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24);
  return daysDifference > numOfDays;
};

export const isElementInViewPort = (element: HTMLElement): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    rect.left >= 0 &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getTimeString = (
  time: number,
  format: 'minutes' | 'hours' = 'minutes'
): string => {
  if (isNullOrUndefined(time)) return '';
  const formatFunctions = {
    minutes: () => `${Math.ceil(time / 60) || 0}min`,
    hours: () => {
      const minutes = time / 60;
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      const roundedHours = remainingMinutes >= 30 ? hours + 1 : hours;
      return `${roundedHours}h`;
    },
  };

  return formatFunctions[format]();
};

export const getDateLabel = (report: WeeklyReportDate): string => {
  return `
      ${dates.getFormattedDate(report?.date_from)} - ${dates.getFormattedDate(
    report?.date_to
  )}`;
};

export const getDataTestId = (
  prefix: string,
  baseName: string,
  uuidPrefix: string,
  uuid: string,
  defaultSuffix?: string
): string => {
  return uuid
    ? `${prefix}_TABLE_${baseName}_${uuidPrefix}${uuid}`
    : `${prefix}_TABLE_${defaultSuffix}`;
};
