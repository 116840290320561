import { roles, stages } from '@innovamat/radiance-utils';
import { useMemo } from 'react';
import { useUser } from '../providers';

type Params = {
  courseOrder: number | null | undefined;
  menu?: string;
};

type ShowAnimatedDigitalPractice = {
  showAnimatedDigitalPractice: boolean;
};

function useShowAnimatedDigitalPractice({
  courseOrder,
  menu,
}: Params): ShowAnimatedDigitalPractice {
  const { user, isStudentViewActive } = useUser();

  const showAnimatedDigitalPractice = useMemo(() => {
    if (!roles.hasTeacherRole(user?.roles) || isStudentViewActive) return false;
    if (!stages.isSecundaria(courseOrder)) return false;
    if (menu !== 'general') return false;

    return true;
  }, [courseOrder, isStudentViewActive, menu, user?.roles]);

  return {
    showAnimatedDigitalPractice,
  };
}

export { useShowAnimatedDigitalPractice };
