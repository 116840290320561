import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';
import { TEST_TYPE, type TestRow } from '../../../../../modules/test/test';

type UseMockExamsValues = {
  isDrawerOpen: string | undefined;
  onOpenDrawer: (id: string) => void;
  onCloseDrawer: () => void;
  onClickNext: () => void;
  onClickPrevious: () => void;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
  mockExamRowIds: string[];
  rowSelected: TestRow | undefined;
};

type MockExamsProviderProps = {
  rows: TestRow[];
  children: ReactNode;
};

const MockExamsContext = createContext<UseMockExamsValues | undefined>(
  undefined
);

export const MockExamsProvider = ({
  rows,
  children,
}: MockExamsProviderProps): JSX.Element => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<string | undefined>(
    undefined
  );

  const mockExamRows = rows.filter((test) => test.type === TEST_TYPE.MOCK);

  const mockExamRowIds = mockExamRows.map((test) => test.id);

  const onOpenDrawer = (id: string): void => {
    setIsDrawerOpen(id);
  };

  const onCloseDrawer = (): void => {
    setIsDrawerOpen(undefined);
  };

  const onClickNext = (): void => {
    if (!isDrawerOpen) return;
    const currentIndex = mockExamRowIds.indexOf(isDrawerOpen);

    if (currentIndex >= 0 && currentIndex < mockExamRowIds.length - 1) {
      setIsDrawerOpen(mockExamRowIds[currentIndex + 1]);
    }
  };

  const onClickPrevious = (): void => {
    if (!isDrawerOpen) return;
    const currentIndex = mockExamRowIds.indexOf(isDrawerOpen);
    if (currentIndex > 0) {
      setIsDrawerOpen(mockExamRowIds[currentIndex - 1]);
    }
  };

  const isNextDisabled =
    mockExamRowIds.indexOf(isDrawerOpen ?? '') === mockExamRowIds.length - 1;
  const isPreviousDisabled = mockExamRowIds.indexOf(isDrawerOpen!) === 0;

  const rowSelected = mockExamRows.find((test) => test.id === isDrawerOpen);

  return (
    <MockExamsContext.Provider
      value={{
        isDrawerOpen,
        onOpenDrawer,
        onCloseDrawer,
        onClickNext,
        onClickPrevious,
        isNextDisabled,
        isPreviousDisabled,
        mockExamRowIds,
        rowSelected,
      }}
    >
      {children}
    </MockExamsContext.Provider>
  );
};

export const useMockExams = (): UseMockExamsValues => {
  const context = useContext(MockExamsContext);
  if (!context) {
    throw new Error('useMockExams must be used within a MockExamsProvider');
  }
  return context;
};
