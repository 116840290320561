import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { IconType } from '@innovamat/glimmer-icons';
import { forwardRef } from 'react';
import { StateLayer } from '../../utils/common.styled';
import { Icon, type IconSize } from '../icon';
import { Typography } from '../typography';

type MenuItemState = 'active' | 'disabled' | 'selected';

type Size = 'M' | 'L' | 'XL' | 'XXL';

export type MenuItemProps = {
  state?: MenuItemState;
  size?: Size;
  icon?: IconType;
  text: string;
  subtleText?: string;
  fill?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  customStyles?: string;
  dataTestId?: string;
  rightIcon?: IconType;
  iconSize?: IconSize;
  rightIconSize?: IconSize;
};

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 12px;
  width: 100%;
  height: 100%;
  z-index: 12;
`;

const OverflowContainer = styled.div`
  position: absolute;
  border-radius: 4px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const sizes = {
  M: '32px',
  L: '40px',
  XL: '48px',
  XXL: '56px',
};
const getSize = (size: Size) => sizes[size];

const Container = styled('button', {
  shouldForwardProp: (prop) => prop !== 'fill',
})<{
  state: MenuItemState;
  size: Size;
  fill?: boolean;
}>`
  border: none;
  position: relative;
  padding: ${({ size }) => (size === 'M' ? '6px 12px' : '12px')};
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  width: ${({ fill }) => (fill ? '100%' : '')};
  min-height: ${({ size }) => getSize(size)};
  height: ${({ size }) => getSize(size)};
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.surface['surface-primary'].value};

  // workaround para que la statelayer pressed se esconda después de la animación de selected
  &:not(:active) .menuItem-stateLayer {
    transition-property: background-color;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    transition-delay: 0.1s;
    border-radius: 4px;
  }

  &:not(:hover) .menuItem-stateLayer {
    transition: none;
  }

  ${({ state, theme }) =>
    state === 'active'
      ? css`
      &:hover .menuItem-stateLayer {
        border-radius: 4px;
        background-color: ${theme.tokens.color.specific['state-layer']['state-hover-darker'].value};
        transition: none;
      }

      &:active .menuItem-stateLayer { {
        border-radius: 4px;
        background-color: ${theme.tokens.color.specific['state-layer']['state-press-darker'].value};
      }
    `
      : state === 'selected'
      ? css`
            svg,
            path {
              fill: ${theme.tokens.color.alias.cm.icon['icon-accent'].value};
            }

            color: ${theme.tokens.color.alias.cm.text['text-accent'].value};
            background-color: transparent;

            * {
              color: ${theme.tokens.color.alias.cm.text['text-accent'].value};
            }
          }
        `
      : state === 'disabled'
      ? css`
          svg,
          path {
            fill: ${theme.tokens.color.alias.cm.icon['icon-disabled'].value};
          }
          pointer-events: none;
          color: ${theme.tokens.color.alias.cm.text['text-disabled'].value};
        `
      : ''}
`;

const Text = styled(Typography.Body2)`
  flex: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubtleText = styled(Typography.Body3)`
  display: inline;
  margin-left: 4px;
  color: ${({ theme }) =>
    theme.tokens.color.alias.cm.text['text-subtle'].value};
`;

const BackgroundLayer = styled.div<{ selected: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 0%;
  opacity: 0;
  border-radius: 20px;
  z-index: 11;
  background-color: ${({ theme }) =>
    theme.tokens.color.alias.cm.bg['bg-accent'].value};
  transition: width 0.3s ease-in-out, opacity 0.2s ease-in-out;

  ${({ selected }) =>
    selected &&
    css`
      opacity: 1;
      width: 110%;
      transition: width 0.3s ease-in-out, opacity 0.1s 0.05s ease-in-out;
    `}
`;

export const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>(
  (props, ref) => {
    const {
      state = 'active',
      size = 'M',
      icon,
      text,
      subtleText,
      fill,
      onClick,
      dataTestId,
      rightIcon,
      iconSize,
      rightIconSize,
    } = props;
    return (
      <Container
        ref={ref}
        state={state}
        fill={fill}
        size={size}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <ContentContainer>
          {icon && <Icon icon={icon} size={iconSize} />}
          <Text>
            {text}
            {subtleText && <SubtleText>{subtleText}</SubtleText>}
          </Text>
          {rightIcon && typeof rightIcon === 'string' ? (
            <Icon icon={rightIcon} size={rightIconSize || 'S'} />
          ) : (
            rightIcon
          )}
        </ContentContainer>
        <StateLayer className="menuItem-stateLayer" />
        <OverflowContainer>
          <BackgroundLayer selected={state === 'selected'} />
        </OverflowContainer>
      </Container>
    );
  }
);
