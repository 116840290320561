import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';
import {
  Icon,
  ImageComponent,
  InnerHtml,
  Typography,
} from '@innovamat/glimmer-components';

const HoverOverlay = styled.div`
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  inset: 0;
  justify-content: space-between;
  opacity: 0;
  padding: 0.58rem 0.58rem 14px 0.58rem;
  position: absolute;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;

  h5 {
    font-size: 0.945rem;
    line-height: 1.08rem;
    letter-spacing: 0.00725rem;
  }
`;

const DisabledOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
`;

const Container = styled.div`
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  transition: filter 0.3s;
  width: 100%;
  cursor: pointer;

  &:hover .hover-overlay {
    opacity: 1;
    visibility: visible;
  }

  &:hover .icon-container {
    opacity: 1;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s;
`;

const Thumbnail = styled(ImageComponent)`
  aspect-ratio: 12/7;
  border-radius: 0.25rem;
  object-fit: cover;
  object-position: bottom;
  width: 100%;
`;

type Props = {
  title: string;
  thumbnail: string;
  onClick: () => void;
  enabled?: boolean;
};

export const AppletCard = ({
  title,
  thumbnail,
  onClick,
  enabled = true,
}: Props): EmotionJSX.Element => {
  return (
    <Container onClick={onClick}>
      <Thumbnail className="thumbnail" src={thumbnail || ''} alt={title} />
      {!enabled && <DisabledOverlay />}
      <HoverOverlay className="hover-overlay">
        <Typography.Subtitle1>
          <InnerHtml text={title} />
        </Typography.Subtitle1>
        <IconContainer className="icon-container">
          <Icon icon="OpenNewTabIcon" iconColor="white" size="S" />
        </IconContainer>
      </HoverOverlay>
    </Container>
  );
};
