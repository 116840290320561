import styled from '@emotion/styled';

import { DIMENSIONS } from '../templates/EarlyYear/EarlyYear.mappers';
import { Dimensions } from '../types/dimensions';
import Block from './Block';
import DimensionBadge from './DimensionBadge';

type Props = {
  dimensions: Dimensions;
  title: string;
  isPrintable?: boolean;
};

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;
  }
`;

export default function DimensionsBlock({
  dimensions,
  title,
  isPrintable,
}: Props) {
  const dimensionKeys = Object.keys(DIMENSIONS);

  const getIsDisabled = (key: string) => {
    const ids = dimensions?.items?.map(
      (item) => item.items.dimension_list.body
    );
    return !ids.includes(key);
  };

  if (!dimensions) return null;

  return (
    <Block title={title}>
      <List>
        {dimensionKeys.map((key) => {
          if (getIsDisabled(key) && isPrintable) return null;
          return (
            <DimensionBadge
              key={key}
              type={key}
              isDisabled={getIsDisabled(key)}
              body={key}
            />
          );
        })}
      </List>
    </Block>
  );
}
