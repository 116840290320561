import { useDevice } from '@innovamat/glimmer-components';
import { useWindowSize } from '@innovamat/hooks';
import { useEffect, useState } from 'react';
import { getInitialSlide } from '../../../utils/sort-resources';
import { CustomArrow } from './custom-arrow';
import {
  sliderResponsiveSettings,
  sliderResponsiveSettingsRelatedItems,
} from './responsive-slider-settings';

type useResponsiveSlideProps = {
  resources?: Array<any> | null;
  sliderRef?: any;
  showMore?: string;
  relatedResources?: boolean;
};
type useResponsiveSlideOutput = {
  slidesToShow: number;
  initialSlide?: number;
  settings: any;
};

const getSlidesToShow = (size?: number): number => {
  if (!size) return 4;
  if (size > 1680) return 5;
  if (size > 1280) return 4;
  if (size > 960) return 3;
  if (size > 600) return 2;
  return 1;
};

export function useResponsiveSlide({
  resources,
  relatedResources,
  sliderRef,
  showMore,
}: useResponsiveSlideProps): useResponsiveSlideOutput {
  const size = useWindowSize();
  const [slidesToShow, setSlidesToShow] = useState(0);
  const initialSlide = getInitialSlide(resources, slidesToShow);
  const { isMobile } = useDevice();

  const settings = {
    infinite: false,
    speed: 500,
    responsive: relatedResources
      ? sliderResponsiveSettingsRelatedItems
      : sliderResponsiveSettings,
    prevArrow: (
      <CustomArrow
        className="slick-prev"
        icon="PreviousIcon"
        onClick={() => {
          sliderRef.current?.slickPrev();
        }}
        dataTestId="prevButtonScrollResources"
      />
    ),
    nextArrow: (
      <CustomArrow
        className="slick-next"
        icon="NextIcon"
        onClick={() => {
          sliderRef.current?.slickNext();
        }}
        dataTestId="nextButtonScrollResources"
      />
    ),
    draggable: !!isMobile,
    swipeToSlide: !!isMobile,
    arrows: relatedResources ? !isMobile : !showMore,
    initialSlide: initialSlide || 0,
  };

  useEffect(() => {
    setSlidesToShow(getSlidesToShow(size.width));
  }, [size]);

  return {
    slidesToShow,
    initialSlide,
    settings,
  };
}
