import styled from '@emotion/styled';

const Container = styled.div`
  position: relative;
`;

const SeparatorWrapper = styled.div<{
  backgroundColor?: string;
  isSmall?: boolean;
}>`
  width: 1px;
  height: ${({ isSmall }) => (isSmall ? '34px' : '56px')};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.tokens.color.global.white.value};
  padding: 0 4.5px;
  margin: -4px 0px;
`;

const Separator = styled.div<{ isDarkSeparator?: boolean }>`
  width: 1px;
  height: 100%;
  margin: 0 -1px;
  background-color: ${({ theme, isDarkSeparator }) =>
    isDarkSeparator
      ? theme.tokens.color.specific.reports.tables['divider-primary'].value
      : theme.tokens.color.specific.reports.tables['divider-secondary'].value};
  position: absolute;
  z-index: 1;
`;

type Props = {
  backgroundColor?: string;
  isDarkSeparator?: boolean;
  isSmall?: boolean;
};

export function LineSeparator({
  backgroundColor,
  isDarkSeparator,
  isSmall,
}: Props): JSX.Element {
  return (
    <Container>
      <SeparatorWrapper
        className="separator-wrapper"
        backgroundColor={backgroundColor}
        isSmall={isSmall}
      >
        <Separator isDarkSeparator={isDarkSeparator} />
      </SeparatorWrapper>
    </Container>
  );
}
