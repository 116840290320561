import { TableComponent } from '@innovamat/glimmer-components';
import { useAdminDashboardQuery } from '@innovamat/glow-api-client';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ERROR_TYPE, ErrorPage } from '../../../navigation';
import ReportsLoader from '../../components/reports-loader';
import type { DashboardProps } from '../admin-dashboard';
import { getAdminColumns } from '../admin-dashboard/utils/get-admin-columns';
import { getRowSpan } from '../admin-dashboard/utils/preprocess';

export const AdminDashboardTable = ({
  organizationId,
  tabsAreLoaded,
  goToClassroom,
  goToTestsAndResults,
  noDataFilter,
  stage,
  setAllRowsFilledOrEmpty,
}: DashboardProps): JSX.Element => {
  const { data, isLoading, isError } = useAdminDashboardQuery(
    {
      organizationId: organizationId!,
      stage: stage,
    },
    { enabled: !!organizationId }
  );

  const someHaveAssessmentPermissions = useMemo(() => {
    return data?.adminDashboard?.some((row) => row?.tests.hasPermission);
  }, [data]);

  const { t } = useTranslation();
  const columns = getAdminColumns({
    t,
    goToClassroom,
    goToTestsAndResults,
    someHaveAssessmentPermissions,
  });

  const rows = useMemo(() => {
    return noDataFilter
      ? data?.adminDashboard?.filter(
          (row) => row?.digitalPractice?.avgScore !== null
        )
      : data?.adminDashboard;
  }, [data, noDataFilter]);

  useEffect(() => {
    if (!data) setAllRowsFilledOrEmpty(undefined);
    if (data) {
      const allFilled = data?.adminDashboard?.every(
        (row) => row?.digitalPractice?.avgScore !== null
      );
      const allEmpty = data?.adminDashboard?.every(
        (row) => !row?.digitalPractice?.avgScore
      );

      setAllRowsFilledOrEmpty(allFilled || allEmpty);
    }
  }, [setAllRowsFilledOrEmpty, data]);

  if (isError) {
    return <ErrorPage errorType={ERROR_TYPE.NOT_AVAILABLE} />;
  }

  return (
    <>
      {isLoading || !tabsAreLoaded ? (
        <ReportsLoader />
      ) : (
        <TableComponent
          id={'ADRE_EARYYEARS'}
          columns={columns}
          rows={data?.adminDashboard ? getRowSpan(rows) : []}
          topStickyOffset={64}
        />
      )}
    </>
  );
};
