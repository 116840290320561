import styled from '@emotion/styled';
import { Button, Tooltip } from '@innovamat/glimmer-components';

type ClassroomElementProps = {
  text: string;
  classroomName: string;
  classroomId: string;
  courseOrder: number;
  goToClassroom?: (classroomId: string) => void;
};

const StyledTooltip = styled(Tooltip)`
  display: block !important;
`;

const TextWrapper = styled.p`
  max-width: 134px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ClassroomElement = ({
  text,
  classroomName,
  classroomId,
  courseOrder,
  goToClassroom,
}: ClassroomElementProps): JSX.Element => {
  return (
    <StyledTooltip content={text} popperOptions={{ strategy: 'fixed' }}>
      <Button
        data-testid={`${courseOrder}_${classroomName}_CLASSROOMNAME`}
        rightIcon="NextWithTextIcon"
        variant="secondary"
        size="S"
        onClick={goToClassroom ? () => goToClassroom(classroomId) : undefined}
        oneLine
      >
        <TextWrapper>{text}</TextWrapper>
      </Button>
    </StyledTooltip>
  );
};

export default ClassroomElement;
