import styled from '@emotion/styled';
import { SimpleBlock } from '../SimpleBlock';
import { Tag } from '../Tag';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@innovamat/glimmer-components';

export type MPTag = {
  tag: string;
  tooltipKey: string;
};

type Props = {
  tags: MPTag[];
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media print {
    gap: 4px;

    .tag-container {
      background-color: ${({ theme }) =>
        theme.tokens.color.global.neutral.neutral200.value};
    }

    .tag-text {
      color: ${({ theme }) =>
        theme.tokens.color.alias.cm.text['text-subtle'].value};
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  overflow: hidden;

  *:hover {
    filter: brightness(0.95);
  }
`;

export function MathPractices({ tags }: Props) {
  const { t } = useTranslation();
  if (tags.length === 0) {
    return null;
  }

  return (
    <SimpleBlock title={t('smartguides.math_practices')}>
      <Container>
        {tags.map((tag, index) => {
          return (
            <StyledTooltip content={t(tag.tooltipKey)}>
              <Tag text={tag.tag} isEnabled={true} />
            </StyledTooltip>
          );
        })}
      </Container>
    </SimpleBlock>
  );
}
