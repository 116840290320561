import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import styled from '@emotion/styled';

const LineContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.61rem;
  height: 0.81rem;
  justify-content: space-between;
  width: 100%;
`;

const ConnectorLine = styled.div`
  backdrop-filter: blur(0.25rem);
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.004rem solid rgba(255, 255, 255, 0);
  height: 0.008rem;
  width: 100%;
`;

const LeftLine = styled(ConnectorLine)<{ isHidden?: boolean }>`
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const RightLine = styled(ConnectorLine)<{ isHidden?: boolean }>`
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const Circle = styled.div<{ isDeactivated?: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 100%;
  border: none;
  display: flex;
  flex-shrink: 0;
  height: 0.75rem;
  justify-content: center;
  opacity: ${({ isDeactivated }) => (isDeactivated ? 0.5 : 1)};
  width: 0.75rem;

  > div {
    height: 0.75rem !important;
    width: 0.75rem !important;
  }
`;
type Props = {
  isHiddenLeft?: boolean;
  isHiddenRight?: boolean;
  isDeactivated?: boolean;
};

const ItemConnector = ({
  isHiddenLeft = false,
  isHiddenRight = false,
  isDeactivated = false,
}: Props): EmotionJSX.Element => (
  <LineContainer>
    <LeftLine isHidden={isHiddenLeft} />
    <Circle isDeactivated={isDeactivated} />
    <RightLine isHidden={isHiddenRight} />
  </LineContainer>
);

export default ItemConnector;
