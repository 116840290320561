import type { UseQueryResult } from '@tanstack/react-query';
import { useCallback } from 'react';

import type { OptionType } from '@innovamat/glimmer-components';
import type {
  Classroom,
  ClassroomsQuery,
  Maybe,
} from '@innovamat/glow-api-client';
import { useClassroomsQuery } from '@innovamat/glow-api-client';
import { stages } from '@innovamat/radiance-utils';

import { useUser } from '../../user-management';

type Props = {
  mine: boolean;
  organizationId?: string;
};

const toOption = (classroom: Classroom): OptionType => ({
  label: `${String(classroom?.courseName)} - ${String(classroom?.name)}`,
  value: String(classroom?.id),
});

type UseClassrooms = {
  classroomsQuery: UseQueryResult<ClassroomsQuery, unknown>;
  classroomsByStage: (
    order: number,
    filterGoogleClassrooms?: boolean
  ) => Classroom[];
  classroomExists: (id: Maybe<string> | undefined) => boolean | undefined;
  classroomToOption: (classroom: Classroom) => OptionType;
  isGoogleClassroom: (id: Maybe<string> | undefined) => boolean;
};

function useClassrooms({ mine, organizationId }: Props): UseClassrooms {
  const { user, isRole } = useUser();
  const orgId = organizationId || user?.organizationId!;

  const classroomsQuery = useClassroomsQuery(
    {
      mine,
      orgId: orgId,
    },
    {
      enabled: !!orgId && !isRole.Student,
      staleTime: Infinity,
    }
  );

  const classroomExists = useCallback(
    (id: Maybe<string> | undefined) => {
      return classroomsQuery.data?.classrooms?.some(
        (classroom) => classroom?.id === id
      );
    },
    [classroomsQuery.data?.classrooms]
  );

  const classroomsByStage = (
    order: number,
    filterGoogleClassrooms?: boolean
  ): Classroom[] => {
    const isSecundariaCourse = stages.isSecundaria(order);
    const classroomsFiltered =
      classroomsQuery.data?.classrooms?.filter((c) =>
        isSecundariaCourse
          ? stages.isSecundaria(c?.courseOrder)
          : !stages.isSecundaria(c?.courseOrder)
      ) || [];
    if (filterGoogleClassrooms) {
      return classroomsFiltered?.filter(
        (c) => c?.provider !== 'google'
      ) as Classroom[];
    }

    return classroomsFiltered as Classroom[];
  };

  const classroomToOption = (classroom: Classroom): OptionType =>
    toOption(classroom);

  const isGoogleClassroom = useCallback(
    (id: Maybe<string> | undefined) => {
      if (classroomExists(id)) {
        return (
          classroomsQuery.data?.classrooms?.find(
            (classroom) => classroom?.id === id
          )?.provider === 'google'
        );
      }
      return false;
    },
    [classroomExists, classroomsQuery.data?.classrooms]
  );

  return {
    classroomsQuery,
    classroomsByStage,
    classroomExists,
    classroomToOption,
    isGoogleClassroom,
  };
}

export { useClassrooms };
