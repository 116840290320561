import { Dimensions } from '../types/Dimension';
import type { DimensionTag } from '../types/SmartGuide';

export const DimensionsMap = {
  problem_solving: Dimensions.PROBLEM,
  reasoning_and_proof: Dimensions.REASONING,
  connections: Dimensions.CONNECTIONS,
  communication_and_representation: Dimensions.COMMUNICATION,
};

export function mapDimensionTags(
  dimensions: any,
  isBubbleTreeStandard: boolean
): DimensionTag[] {
  if (!dimensions || !isBubbleTreeStandard) return [];

  return Object.entries(dimensions).map(([key, value]) => {
    return {
      textKey: `digitalguides.dimensions.${key}`,
      isEnabled: value as boolean,
      dimension: DimensionsMap[key],
    } as unknown as DimensionTag;
  });
}
