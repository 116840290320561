import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Accordion as GlimmerAccordion } from '@innovamat/glimmer-components';
import { usePartColors } from '../../hooks/usePartColors';
import type { PartType } from '../../types/SmartGuide';
import { PrintableSubtitle2 } from '../../printable-styles/printable-typography';

const PrintedStyledAccordion = styled.div<{
  hasBorder: boolean;
  bg?: string;
}>`
  break-inside: avoid;
  padding: 12px 16px;
  border-radius: 4px;
  background: ${({ bg }) => bg};
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      outline: solid 1px #e5e7eb;
      outline-offset: -1px;
    `}

  .mediaContainer {
    margin: 16px 0;

    @media print {
      margin: 8px 0;
    }
  }

  @media print {
    padding: 8px;
  }
`;

const PrintedSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledAccordion = styled(GlimmerAccordion)<{
  hasBorder: boolean;
  bg?: string;
}>`
  break-inside: avoid;
  ${({ hasBorder }) =>
    !hasBorder &&
    css`
      outline: none;
    `}

  ${({ bg }) => css`
    background-color: ${bg};
  `}
`;

const Summary = styled(PrintableSubtitle2)<{ color?: string }>``;

export type AccordionProps = {
  part?: PartType;
  children: React.ReactNode;
  summary: string;
  hasPreview?: boolean;
  isPrintable?: boolean;
  className?: string;
};

export function Accordion({
  part,
  children,
  summary,
  hasPreview,
  isPrintable,
  className,
}: AccordionProps): JSX.Element {
  const { getBackground, getElement } = usePartColors();

  const renderTitle = (): JSX.Element => {
    return <Summary color={getElement(part)}>{summary}</Summary>;
  };

  const hasBorder = part === undefined;

  if (isPrintable) {
    return (
      <PrintedStyledAccordion
        hasBorder={hasBorder}
        bg={getBackground(part)}
        className={className}
      >
        <PrintedSummaryWrapper>{renderTitle()}</PrintedSummaryWrapper>
        <div>{children}</div>
      </PrintedStyledAccordion>
    );
  }
  return (
    <StyledAccordion
      summary={renderTitle()}
      hasPreview={hasPreview}
      size="S"
      hasBorder={hasBorder}
      bg={getBackground(part)}
      isInteractive={!isPrintable}
      isExpanded={isPrintable}
      className={className}
    >
      {children}
    </StyledAccordion>
  );
}
