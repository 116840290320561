import {
  renderClassroom,
  renderCompletedSessions,
  renderCourseElement,
  renderDigitalPractice,
  renderMultipleSublabels,
  renderStudents,
  renderTeachers,
  renderTests,
} from './render-admin-rows';

import type { Column, Row } from '@innovamat/glimmer-components';
import type { TFunction } from 'i18next';

type Props = {
  t: TFunction<'translation', undefined, 'translation'>;
  goToClassroom?: (classroomId: string) => void;
  goToTestsAndResults?: (classroomId: string) => void;
  someHaveAssessmentPermissions?: boolean;
};

export const getAdminColumns = ({
  t,
  goToClassroom,
  goToTestsAndResults,
  someHaveAssessmentPermissions,
}: Props): Column<Row>[] => {
  const columns: Column<Row>[] = [];

  columns.push(
    {
      id: 'course',
      label: t('reports.admin.title.course'),
      width: 150,
      sticky: true,
      render: (value) => renderCourseElement(value),
      rowSpan: (value) => value.rowSpan,
      align: 'left',
      dataTestId: 'COURSE',
    },
    {
      id: 'classroom',
      label: t('reports.admin.title.classroom'),
      width: 150,
      sticky: true,
      render: (value, row: Row) =>
        renderClassroom({
          value,
          classroomId: row.classroomId,
          classroomName: row.classroom,
          courseOrder: row.course.courseOrder,
          goToClassroom,
        }),
      align: 'left',
      dataTestId: 'CLASSROOMNAME',
    },
    {
      id: 'separator-1',
      width: 9,
      separator: true,
      sticky: true,
    },
    {
      id: 'sessions',
      label: t('reports.admin.title.completedSessions'),
      width: 120,
      align: 'left',
      infoColumn: t('reports.admin.sessionsColumn.tooltip'),
      render: (value, row: Row): JSX.Element =>
        renderCompletedSessions({
          completed: value.completed,
          total: value.total,
          lab: value.completed_laboratory,
          adv: value.completed_adventure,
          classroomName: row.classroom,
          courseOrder: row.course.courseOrder,
          rowIsNull:
            row.teachers.lastConnection === '' &&
            row.digitalPractice.avgScore === null,
        }),
      dataTestId: 'COMPLETEDSESSIONS',
    },
    {
      id: 'separator-2',
      width: 9,
      separator: true,
    },
    {
      id: 'digitalPractice',
      label: t('reports.admin.title.digitalPractice'),
      width: 172, // poner 280px cuando tengamos adquiridos. poner 350px cuando tengamos la columna de media de innovamat
      subLabel: () =>
        renderMultipleSublabels({
          values: [
            t('reports.admin.subtitle.digitalPractice.averageScore'),
            {
              text: t('reports.admin.subtitle.averageInnovamat'),
              tooltip: t(
                'reports.admin.subtitle.digitalPractice.averageInnovamat.tooltip'
              ),
              isHidden: true,
            },
            {
              text: t(
                'reports.admin.subtitle.digitalPractice.acquiredContents'
              ),
              tooltip: t(
                'reports.admin.subtitle.digitalPractice.acquiredContents.tooltip'
              ),
              infoTooltip: t('reports.admin.acquiredContentsColumn.tooltip'),
              isHidden: true,
            },
          ],
          proportions: '2.5fr 1fr 1.4fr',
        }),
      render: (value, row: Row) =>
        renderDigitalPractice({
          value,
          proportions: '2.5fr 1fr 1.4fr',
          rowIsNull: row.digitalPractice.avgScore === null,
          classroomName: row.classroom,
          courseOrder: row.course.courseOrder,
        }),
      dataTestId: 'DIGITALPRACTICE',
    }
  );

  if (someHaveAssessmentPermissions) {
    columns.push(
      {
        id: 'separator-3',
        isHiddenColumn: !someHaveAssessmentPermissions,
        width: 9,
        separator: true,
      },
      {
        id: 'tests',
        isHiddenColumn: !someHaveAssessmentPermissions,
        label: t('reports.admin.title.tests'),
        subLabel: t('reports.admin.subtitle.tests'),
        width: 84,
        render: (value, row: Row) =>
          renderTests({
            value,
            goToTestsAndResults,
            classroomId: row?.classroomId,
            t,
          }),
        dataTestId: 'DIGITALPRACTICE',
      }
    );
  }

  columns.push(
    {
      id: 'separator-4',
      width: 9,
      separator: true,
    },
    {
      id: 'students',
      label: `${t('reports.admin.title.students')} - ${t(
        'reports.admin.title.students.lastDays'
      )} `,
      width: 200,
      subLabel: () =>
        renderMultipleSublabels({
          values: [
            {
              text: `% ${t(
                'reports.admin.subtitle.students.connectedPercentage'
              )}`,
              dataTestId: 'ADRE_STUDENTSPERCENTAGE',
            },
            {
              text: t('reports.admin.subtitle.students.averageTime'),
              tooltip: t(
                'reports.admin.subtitle.students.averagetTime.tooltip'
              ),
              dataTestId: 'ADRE_STUDENTSCLASSROOM',
            },
          ],
          proportions: '1fr 1fr',
        }),
      render: (value, row: Row) =>
        renderStudents({
          value,
          proportions: '1fr 1fr',
          classroomName: row.classroom,
          courseOrder: row.course.courseOrder,
          rowIsNull: row.digitalPractice.avgScore === null,
        }),
    },
    {
      id: 'separator-4',
      width: 9,
      separator: true,
    },
    {
      id: 'teachers',
      label: t('reports.admin.title.teachers'),
      subLabel: t('reports.admin.subtitle.lastConnection'),
      width: '100%',
      minWidth: 120,
      render: (value, row: Row) =>
        renderTeachers({
          firstName: value.firstName,
          lastName: value.lastName,
          classroomName: row.classroom,
          courseOrder: row.course.courseOrder,
          lastConnection: value.lastConnection,
          rowIsNull: value.lastConnection === '',
        }),
      dataTestId: 'TEACHERCLASSROOM',
    }
  );

  return columns;
};
