import type { BigIdea } from '@innovamat/resource-viewer';
import type { BigIdeaTag } from '../types/SmartGuide';

const getBigIdeaTooltipKey = (bigIdea: BigIdea): string => {
  return `bigidea.${bigIdea.courseOrder}.${bigIdea.contentConnection}.${bigIdea.item}`;
};

const mapBigIdeas = (bigIdeas?: BigIdea[]): BigIdeaTag[] =>
  bigIdeas?.map((bigIdea: BigIdea) => ({
    text: getBigIdeaTooltipKey(bigIdea),
    contentConnection:
      bigIdea.contentConnection as BigIdeaTag['contentConnection'],
  })) || [];

export { mapBigIdeas };
