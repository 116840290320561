import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import {
  AnnouncementPanel,
  AppletCard,
  Skeleton,
  Typography,
} from '@innovamat/glimmer-components';
import type { AppletsBySessionCodeQuery } from '@innovamat/glow-api-client';
import { useWebviewBridge } from '@innovamat/webview-bridge';
import { useEffect, useState } from 'react';
import { useSessionDone } from '../../hooks/session-done-provider';
import { Divider } from './session-done-modal.styles';

const APPLET_REMOVAL_DELAY_SEC = 0.15;

const slideOut = keyframes`
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-50px);
    opacity: 0;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0;
  }
`;

const AppletsWrapper = styled.div<{ isLoading?: boolean }>`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  height: 150px;
  ${({ isLoading }) => isLoading && 'overflow-x: hidden;'}
  overflow-y: hidden;
`;

const AppletCardWrapper = styled.div<{ isRemoving?: boolean }>`
  animation: ${({ isRemoving }) => (isRemoving ? slideOut : 'none')}
    ${APPLET_REMOVAL_DELAY_SEC}s ease-out;
  transition: transform ${APPLET_REMOVAL_DELAY_SEC}s ease-out,
    opacity ${APPLET_REMOVAL_DELAY_SEC}s ease-out;
`;

type Props = {
  applets: AppletsBySessionCodeQuery['appletsBySessionCode'] | undefined;
  isError: boolean;
  isLoadingApplets?: boolean;
  isModalClosing: boolean;
  closingWithAnimation?: boolean;
  onFinishedClose?: () => void;
};

export default function UnlockedAppletsList({
  applets,
  isError,
  isLoadingApplets,
  isModalClosing,
  closingWithAnimation,
  onFinishedClose,
}: Props): JSX.Element {
  const { t, onGoToApplet } = useSessionDone();
  const { isInWebview } = useWebviewBridge();

  const [visibleApplets, setVisibleApplets] = useState<
    AppletsBySessionCodeQuery['appletsBySessionCode']
  >([]);

  useEffect(() => {
    if (!isLoadingApplets && applets) {
      setVisibleApplets(applets);
    }
  }, [isLoadingApplets, applets]);

  useEffect(() => {
    if (!closingWithAnimation) {
      return;
    }

    if (isModalClosing === true) {
      if (!visibleApplets?.length) {
        onFinishedClose?.();
        return;
      }

      let index = visibleApplets.length - 1;
      const interval = setInterval(() => {
        if (index < 0) {
          clearInterval(interval);
          onFinishedClose?.();
          return;
        }
        setVisibleApplets((prev) => prev?.slice(0, -1));
        index--;
      }, APPLET_REMOVAL_DELAY_SEC * 1000);

      return () => clearInterval(interval);
    }
    return;
  }, [closingWithAnimation, isModalClosing, visibleApplets, onFinishedClose]);

  const renderContent = (): JSX.Element => {
    if (isLoadingApplets) {
      return (
        <AppletsWrapper isLoading>
          {Array.from(Array(4).keys()).map((index) => (
            <div
              key={index}
              style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
            >
              <Skeleton height="100" width="180" radius="8" />
              <Skeleton height="21" width="180" radius="4" />
            </div>
          ))}
        </AppletsWrapper>
      );
    }

    if (applets === null || applets === undefined || isError) {
      return (
        <AnnouncementPanel
          text={t('session.sessionApplets.messageError')}
          canClose={false}
          type="info"
        />
      );
    }

    if (applets.length === 0) {
      return (
        <AnnouncementPanel
          text={t('session.sessionApplets.already_unlocked')}
          canClose={false}
        />
      );
    }

    return (
      <AppletsWrapper data-testid="appletsContainerModal">
        {visibleApplets?.map((applet, index) => (
          <AppletCardWrapper
            key={applet?.data?.id}
            isRemoving={visibleApplets.length - 1 === index}
          >
            <AppletCard
              thumbnail={applet?.data?.thumbnail!}
              name={applet?.data?.name!}
              isDisabled={isInWebview}
              onClick={() => onGoToApplet(applet?.data?.id!)}
            />
          </AppletCardWrapper>
        ))}
      </AppletsWrapper>
    );
  };

  return (
    <Container>
      <Typography.Body1>
        {t('session.unlockModal.description')}
      </Typography.Body1>

      {renderContent()}
      <Divider margin="bottom" />
    </Container>
  );
}
