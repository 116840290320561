import { useId, useMemo } from 'react';

import styled from '@emotion/styled';
import { replaceLatex } from '../../utils/htmlParser/replaceLatex';
import { replaceOldDimension } from '../../utils/htmlParser/replaceOldDimension';

import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser';
import parse, { domToReact } from 'html-react-parser';
import { useSmartGuides } from '../../context/SmartGuidesProvider';
import type { Dimension } from '../../types/Dimension';
import {
  DimensionSpan,
  MaterialSpan,
  PlayerLink,
} from '../../utils/htmlParser/components';
import { replaceHTMLElements } from '../../utils/htmlParser/replace-html-elements/replace-html-elements';
import { replaceLineBreaks } from '../../utils/htmlParser/replace-line-breaks/replace-line-breaks';
import { replaceLocalization } from '../../utils/htmlParser/replace-localization/replace-localization';
import { getMaterialsByStage } from '../../utils/materialsList';
import { navigateToResource } from '../../utils/navigateToResource';

type Props = {
  text: string | undefined;
  className?: string;
};

const StyledDiv = styled.div`
  table {
    border-collapse: collapse;
    margin: 4px 16px;
    display: inline-table;
    width: calc(100% - 32px) !important;

    td {
      padding: 0.5rem;
      border: 1px solid;
      border-color: ${({ theme }) =>
        theme.tokens.color.alias.cm.border['border-subtle'].value};
    }
  }

  p + table {
    margin-top: 8px;
  }

  ul,
  ol {
    display: flex;
    flex-direction: column;
    gap: 4px;

    li {
      margin-left: 24px;
    }

    @media print {
      gap: 2px;

      li {
        margin-left: 18px;
      }
    }
  }

  p + ul,
  p + ol {
    margin-top: 4px;
    gap: 4px;
  }
`;

const StyledInnerHtml = ({
  children,
  className,
}: {
  children: any;
  className?: string;
}): JSX.Element => {
  return <StyledDiv className={className}>{children}</StyledDiv>;
};

export function PreprInnerHtml({ text = '', className }: Props): JSX.Element {
  const { t, stage, playerInfo } = useSmartGuides();

  const regionParams = playerInfo?.appletInfo?.regionParameters;

  const materialList = useMemo(() => getMaterialsByStage(stage), [stage]);

  const id = useId();

  const transformedContent = useMemo(() => {
    if (!text) return '';
    let processedContent = text;
    processedContent = replaceLineBreaks(processedContent);
    processedContent = replaceLocalization(processedContent, t, regionParams);
    processedContent = replaceOldDimension(processedContent);
    processedContent = replaceLatex(processedContent, id);
    processedContent = replaceHTMLElements(processedContent);
    return processedContent;
  }, [text, id, regionParams, t]);

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode.type === 'tag' && domNode.name === 'dimensioncomponent') {
        const { dimensionid } = domNode.attribs;
        return (
          <DimensionSpan
            label={domToReact(domNode.children as DOMNode[], options) as string}
            dimension={+dimensionid as Dimension}
          />
        );
      }
      if (domNode.type === 'tag' && domNode.name === 'playercomponent') {
        const { videoid } = domNode.attribs;
        const label = domToReact(
          domNode.children as DOMNode[],
          options
        ) as string;
        return (
          <PlayerLink
            label={label}
            id={videoid}
            navigateToResource={navigateToResource}
          />
        );
      }

      if (domNode.type === 'tag' && domNode.name === 'materialcomponent') {
        const { materialid } = domNode.attribs;
        const label = domToReact(
          domNode.children as DOMNode[],
          options
        ) as string;
        return (
          <MaterialSpan
            label={label}
            image={materialList[materialid]?.image}
            text={t(materialList[materialid]?.key)}
          />
        );
      }

      return domNode.type === 'text' && domNode.data;
    },
  };

  return (
    <StyledInnerHtml className={className}>
      {parse(transformedContent, options)}
    </StyledInnerHtml>
  );
}
